import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Link from 'components/common/link';
import i18n from 'utils/i18n';
import UserService from 'services/userService';
import { getUrl } from 'utils/resource';

const UserViewContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 6.5}px`,
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  // width: 'fit-content',
  img: {
    width: '180px',
    aspectRatio: '1/1',
    borderRadius: '50%',
  },
  '.avatar': {
    width: '180px',
    height: '180px',
  },
  '.col': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 4}px`,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
  },
  '.item': {
    width: 'fit-content',
    flex: 1,
    '& p:first-of-type': {
      color: theme.palette.grey[600],
    },
    '& p:last-of-type': {
      color: theme.palette.primary.main,
    },
    wordWrap: 'break-word',
    // overflowWrap: 'anywhere',
  },
}));

const UserView = ({ id, data }) => {
  const [user, setUser] = useState(null);

  const fetchData = async () => {
    let userData;
    if (id) {
      userData = await UserService.getOne(id);
      setUser(userData.data);
    } else if (data) {
      setUser(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      {!user && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {user && (
        <UserViewContainer>
          <div className="col">
            <div className="item">
              <Typography variant="body2">{i18n('NAME')}</Typography>
              <Typography>{user.azureName}</Typography>
              <br />
              {/* <img src={user.azureProfilePhoto ? getUrl(user.azureProfilePhoto) : '/img_bg.png'} alt="img" /> */}
              <Avatar className="avatar" src={getUrl(user.azureProfilePhoto)} />
            </div>
          </div>
          <div className="col">
            <div className="item">
              <Typography variant="body2">{i18n('JOB_DESC')}</Typography>
              <Typography>{user.azureJob}</Typography>
            </div>
            <div className="item">
              <Typography variant="body2">{i18n('DEPARTMENT')}</Typography>
              <Typography>{user.azureDepartment}</Typography>

            </div>
            <div className="item">
              <Typography variant="body2">{i18n('MANAGER')}</Typography>
              <Typography>
                <Link to={`/user/${user.azureManager?.id}`}>{user.azureManager?.azureName}</Link>
              </Typography>
            </div>
          </div>
          <div className="col">
            <div className="item">
              <Typography variant="body2">{i18n('EMAIL')}</Typography>
              <Typography>{user.azurePreferredUsername}</Typography>

            </div>
            <div className="item">
              <Typography variant="body2">{i18n('PHONE')}</Typography>
              <Typography>{user.azurePhone}</Typography>
            </div>
            <div className="item">
              <Typography variant="body2">{i18n('SOCIAL')}</Typography>
              <Typography>{user.linkedinUrl}</Typography>
            </div>
          </div>
          <div className="col">
            <div className="item">
              <Typography variant="body2">{i18n('OFFICE')}</Typography>
              <Typography>{user.azureOffice?.name}</Typography>
            </div>
            <div className="item">
              <Typography variant="body2">{i18n('COUNTRY')}</Typography>
              <Typography>{user.azureCountry?.name}</Typography>
            </div>
            <div className="item">
              <Typography variant="body2">{i18n('START_DATE')}</Typography>
              <Typography>{user.azureHireDate}</Typography>
            </div>
          </div>

        </UserViewContainer>
      )}
    </>
  );
};

UserView.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object,
};

UserView.defaultProps = {
  id: null,
  data: null,
};

export default UserView;
