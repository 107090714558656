import DataSelect from 'components/common/dataSelect';
import ConfigService from 'services/configService';

const NewsCategorySelect = (props) => {
  const dataPromise = () => ConfigService.getOne('news-category');

  return (
    <DataSelect
      dataPromise={dataPromise}
      itemLabelPath="name"
      itemValuePath="id"
      {...props}
    />
  );
};

export default NewsCategorySelect;
