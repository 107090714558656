import config from 'config';
import GenericService from './genericService';

class PostService extends GenericService {
  constructor() {
    super(config.API.POST);
  }

  async addImage(id, image) {
    return this.genericReqMultipart({ image }, { urlExtra: `/${id}/image`, method: 'PUT' });
  }
}

export default new PostService();
