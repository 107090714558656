import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from 'components/common/paper';
import EditButton from 'components/common/editButton';
import i18n from 'utils/i18n';
import { getUrl } from 'utils/resource';
import { userCanEdit } from 'utils/session';
import routes from 'routes.json';
import PostTag from './tag';

const canEdit = userCanEdit();

const CardPaper = styled(Paper)(({ theme }) => ({
  width: 'calc((100% / 4) - 18px)',
  [theme.breakpoints.down('xl')]: {
    width: 'calc((100% / 3) - 16px)',
  },
  [theme.breakpoints.down('lg')]: {
    width: 'calc((100% / 2) - 24px)',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 2}px`,
  cursor: 'pointer',
  '.info': {
    display: 'flex',
    gap: `${theme.basicGap}px`,
    flexWrap: 'wrap',
    '.info-item': {
      display: 'flex',
      padding: `${theme.basicGap}px ${theme.basicGap * 2}px`,
      fontSize: '0.875rem',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '8px',
      lineHeight: 1,
    },
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  '.post-img': {
    width: '100%',
    aspectRatio: '1.5/1',
    borderRadius: '8px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

/**
 * Renders a post card
 */
const PostCard = ({ post }) => {
  const navigate = useNavigate();

  const handleEditClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    navigate(`/${routes.POST_EDIT.replace(':id', post?.id)}`);
  };

  return (
    <CardPaper elevation={0}>
      <Link to={`/post/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <CardContainer>
          <div style={{ display: 'flex', justifyContent: post?.qClub ? 'space-between' : 'flex-end' }}>
            {post?.qClub && (
              <PostTag type={post?.type} qclub={post?.qClub} subQClub={post?.qKnowledgeCategory} />
            )}
            {canEdit && (
              <div style={{ zIndex: 10 }}>
                <EditButton
                  onClick={handleEditClick}
                />
              </div>
            )}
          </div>
          <div
            className="post-img"
            style={{
              backgroundImage: `url(${getUrl(post?.image) || '/img_bg.png'})`,
              backgroundSize: post?.image ? 'cover' : 'contain',
            }}
          />
          <Typography className="medium" color="secondary">{post.title}</Typography>
          <Typography sx={{ fontSize: '0.875rem' }}>{post.headline}</Typography>
          <div className="info">
            <div className="info-item">
              {format(new Date(post?.eventDate || post?.visibleFrom || post?.createdAt || null), 'dd/MM/yyyy')}
            </div>
            {
              post?.offices?.map((office, idx) => (
                <div className="info-item" key={`post_off_${idx}`}>
                  {office.name}
                </div>
              ))
            }
            {
              post?.allOffices && (
                <div className="info-item">
                  {i18n('GLOBAL')}
                </div>
              )
            }
          </div>
        </CardContainer>
      </Link>
    </CardPaper>
  );
};

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostCard;
