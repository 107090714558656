import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Brightness5OutlinedIcon from '@mui/icons-material/Brightness5Outlined';
import SolarPowerOutlinedIcon from '@mui/icons-material/SolarPowerOutlined';
import WindPowerOutlinedIcon from '@mui/icons-material/WindPowerOutlined';
import ChargingStationOutlinedIcon from '@mui/icons-material/ChargingStationOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
import i18n from 'utils/i18n';
import Paper from 'components/common/paper';
import EditButton from 'components/common/editButton';
import Button from 'components/common/button';
import { getContentKey } from 'utils/content';
import { userCanAdmin } from 'utils/session';
import FormItem from './formItem';

const EnergiesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 3}px`,
  '.MuiSvgIcon-root': {
    verticalAlign: 'middle',
  },
  '.portfolio div': {
    paddingTop: `${theme.basicGap * 2}px`,
  },
  iframe: {
    height: '100%',
    border: 'none',
    margin: '0 auto',
  },
  form: {
    height: '100%',
  },
  '.inputStyle': {
    '& input,p': {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '2.125rem',
    },
  },
}));

const TitleRow = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const CONTENT_PREFIX = 'about.energy.';

const Energies = ({ data, onSubmit }) => {
  const [isAdminView, setIsAdminView] = useState(false);
  const canAdmin = userCanAdmin();

  const handleEditClick = () => setIsAdminView((prev) => !prev);

  const numerOfCells = 4;
  const initialValues = new Array(numerOfCells).fill(null).map((v, idx) => idx).reduce((prev, idx) => {
    const obj = { ...prev };
    const key = `cell${idx + 1}`;
    obj[key] = getContentKey(data, `${CONTENT_PREFIX}${key}`);
    return obj;
  }, {});

  const validationSchema = Yup.object().shape(
    new Array(numerOfCells).fill(null).map((v, idx) => idx).reduce((prev, idx) => {
      const obj = { ...prev };
      const key = `cell${idx + 1}`;
      obj[key] = Yup.string().required(i18n('MANDATORY'));
      return obj;
    }, {}),
  );

  const wrappedSubmit = (values) => {
    const parsedValues = Object.keys(values)?.reduce((prev, key) => {
      const obj = { ...prev };
      obj[`${CONTENT_PREFIX}${key}`] = values[key];
      return obj;
    }, {});
    onSubmit(parsedValues);
    setIsAdminView(false);
  };

  return (
    <EnergiesContainer id="energies">
      <Paper elevation={0} className="portfolio" sx={{ flex: 1 }}>
        <Typography className="medium">{i18n('PORTFOLIO')}</Typography>
        <br />
        <div>
          <Brightness5OutlinedIcon color="secondary" />
          {' '}
          {i18n('SOLAR_PV')}
        </div>
        <div>
          <SolarPowerOutlinedIcon color="secondary" />
          {' '}
          {i18n('CSP')}
        </div>
        <div>
          <WindPowerOutlinedIcon color="secondary" />
          {' '}
          {i18n('WIND')}
        </div>
        <div>
          <ChargingStationOutlinedIcon color="secondary" />
          {' '}
          {i18n('BATTERIES')}
        </div>
        <div>
          <EnergySavingsLeafOutlinedIcon color="secondary" />
          {' '}
          {i18n('RNG')}
        </div>
        <div>
          <WavesOutlinedIcon color="secondary" />
          {' '}
          {i18n('HYDROELECTRIC')}
        </div>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          flex: 1,
          border: (canAdmin && isAdminView ? '1px dashed' : 'none'),
          borderColor: 'grey.400',
        }}
      >

        <Formik
          initialValues={initialValues}
          onSubmit={wrappedSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, resetForm }) => (
            <Form>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <TitleRow>
                  <Typography className="medium">{i18n('TOTAL_MW_QE')}</Typography>
                  {canAdmin && (
                    <div style={{ display: 'flex', gap: '8px' }}>
                      {isAdminView && (
                      <Button variant="light" type="submit">{i18n('SAVE')}</Button>
                      )}
                      <EditButton
                        variant={isAdminView ? 'disabled' : null}
                        onClick={() => {
                          resetForm({ values: initialValues });
                          handleEditClick();
                        }}
                      />
                    </div>
                  )}
                </TitleRow>
                <div style={{ flex: 1 }}>
                  <br />
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell1" errors={errors} typoVariant="h4" extraClass="inputStyle" />
                  <FormItem
                    isAdminView={canAdmin && isAdminView}
                    data={data}
                    itemPrefix={CONTENT_PREFIX}
                    itemKey="cell2"
                    errors={errors}
                    extra={i18n('ABOUT_ENERGY_IN_OP')}
                    color="primary"
                    typoVariant="body1"
                    fieldSx={{ width: '80px' }}
                  />
                  <FormItem
                    isAdminView={canAdmin && isAdminView}
                    data={data}
                    itemPrefix={CONTENT_PREFIX}
                    itemKey="cell3"
                    errors={errors}
                    extra={i18n('ABOUT_ENERGY_IN_DEV')}
                    color="primary"
                    typoVariant="body1"
                    fieldSx={{ width: '80px' }}
                  />
                </div>
                <div>
                  <em>
                    <FormItem
                      isAdminView={canAdmin && isAdminView}
                      data={data}
                      itemPrefix={CONTENT_PREFIX}
                      itemKey="cell4"
                      errors={errors}
                      color="primary"
                      typoVariant="body1"
                    />
                  </em>

                </div>
              </div>
            </Form>
          )}
        </Formik>

      </Paper>
      <Paper elevation={0} sx={{ flex: 2 }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div>
            <Typography className="medium">{i18n('TOTAL_MW_COUNTRY')}</Typography>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <br />
            <iframe src="https://datawrapper.dwcdn.net/bpM9o/1/" title="Chart" />
          </div>
        </div>
      </Paper>
    </EnergiesContainer>
  );
};

Energies.propTypes = {
  data: PropTypes.array,
  onSubmit: PropTypes.func,
};

Energies.defaultProps = {
  data: [],
  onSubmit: () => {},
};

export default Energies;
