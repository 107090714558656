import routes from 'routes.json';
import { CONTENT_TYPES } from 'components/entities/post/params';

const getBackUrl = (post) => {
  if (!post?.qClub) {
    if (post?.type === CONTENT_TYPES.NEWS) {
      return `/${routes.NEWS}`;
    }
    return `/${routes.EVENTS}`;
  }
  const targetQKnowCategory = (post?.qKnowledgeCategory && post?.qKnowledgeCategory !== CONTENT_TYPES.NEWS) ? post?.qKnowledgeCategory : null;
  return `/${routes.QCLUBS.replace(':qClub?', post?.qClub).replace(':subQclub?', targetQKnowCategory || '')}`;
};

export default getBackUrl;
export { getBackUrl };
