import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isAfter from 'date-fns/isAfter';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Dialog from 'components/common/dialog';
import i18n from 'utils/i18n';

const DialogContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 3}px`,
  '.select-strategy': {
    display: 'flex',
    paddingTop: `${theme.basicGap * 3}px`,
    gap: `${theme.basicGap * 3}px`,
    color: theme.palette.primary.main,
    '.item': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.basicGap}px`,
      padding: `${theme.basicGap * 2}px`,
      borderRadius: '8px',
      border: `1px solid ${theme.palette.primary.main}`,
      width: '25rem',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: theme.palette.action.hover,
      },
      alignItems: 'center',
    },
    '.active': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '.schedule-form': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap}px`,
    '.MuiInputBase-root': {
      maxWidth: '16rem',
      borderRadius: '8px',
    },
    '.MuiFormHelperText-root ': {
      margin: '0px',
    },
  },
}));

const SubmitDialog = ({
  onSubmit, onCancel, editing, editionMode, ...props
}) => {
  const isScheduled = () => editing && isAfter(editing, new Date());
  const [postStrategy, setPostStrategy] = useState(isScheduled() ? 0 : 1);

  const initialValues = {
    visibleFrom: editing || null,
  };

  const validationSchema = Yup.object().shape({
    visibleFrom: postStrategy === 0 ? Yup.date().required(i18n('MANDATORY')) : Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const wrappedSubmit = async () => {
    const result = await formik.validateForm();
    if (!Object.keys(result)?.length) {
      onSubmit(postStrategy === 0 ? formik.values : {});
    }
  };

  const confirmButtons = [{
    label: 'CANCEL',
    componentProps: {
      variant: 'grey',
      onClick: () => onCancel(),
    },
  },
  {
    label: postStrategy === 0 ? 'SCHEDULE' : (editionMode ? 'SAVE' : 'POST'),
    componentProps: {
      variant: 'light',
      onClick: () => wrappedSubmit(),
    },
  },
  ];

  return (
    <Dialog
      buttons={confirmButtons}
      buttonsPosition="flex-end"
      sx={{
        '.MuiPaper-root': { maxWidth: '900px' },
      }}
      {...props}
    >
      <Typography color="secondary" className="medium" sx={{ fontSize: '1.125rem' }}>{i18n('POST_STRATEGY')}</Typography>
      <DialogContainer>
        <div className="select-strategy">
          <div className={`item ${postStrategy === 0 ? 'active' : ''}`} onClick={() => setPostStrategy(0)}>
            <ScheduleIcon sx={{ width: '1.875rem' }} />
            <Typography className="medium" sx={{ fontSize: '1.125rem' }}>{i18n('SCHEDULE')}</Typography>
            <Typography color="grey.500">{i18n('POST_FORM_SCHEDULE_DESC')}</Typography>
          </div>
          <div
            className={`item ${postStrategy === 1 ? 'active' : ''}`}
            onClick={() => {
              setPostStrategy(1);
              formik.setFieldValue('visibleFrom', null);
            }}
          >
            <SendIcon sx={{ width: '1.875rem' }} />
            <Typography className="medium" sx={{ fontSize: '1.125rem' }}>{i18n('POST_NOW')}</Typography>
            <Typography color="grey.500">{i18n('POST_FORM_POST_NOW_DESC')}</Typography>
          </div>
        </div>
        {postStrategy === 0 && (
        <div className="schedule-form">
          <Typography color="primary">{i18n('POST_FORM_SELECT_TIME')}</Typography>
          <DateTimePicker
            format="dd/MM/yyyy HH:mm"
            as={DateTimePicker}
            name="visibleFrom"
            value={formik.values.visibleFrom}
            onChange={(value) => formik.setFieldValue('visibleFrom', value ? new Date(value) : value)}
            slotProps={{
              textField: {
                size: 'small',
                error: Boolean(formik.errors.visibleFrom),
                helperText: formik.errors.visibleFrom,
              },
            }}
          />
        </div>
        )}
      </DialogContainer>
    </Dialog>
  );
};

SubmitDialog.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  editionMode: PropTypes.bool,
  editing: PropTypes.instanceOf(Date),
};

SubmitDialog.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  editionMode: false,
  editing: null,
};

export default SubmitDialog;
