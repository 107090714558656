import i18n from 'utils/i18n';

const postFormToApi = ({ image, ...values }) => ({
  ...values,
  audienceOfficeIds: values?.audienceOffices?.map((office) => office.id) || [],
  officeIds: values?.offices?.map((office) => office.id) || [],
  newsCategoryId: values?.newsCategory?.id,
  qClub: values?.qClub?.value,
  type: values?.type && typeof values.type === 'object' ? values.type?.value : values?.type,
  qKnowledgeCategory: values?.qKnowledgeCategory?.value,
  visibleFrom: values?.visibleFrom || new Date(),
});

const postApiToForm = ({
  type, title, headline, image, content, qClub, qKnowledgeCategory, eventDate, visibleFrom, ...restOfStepOneData
}) => ({
  type,
  stepOneData: {
    eventDate: eventDate ? new Date(eventDate) : eventDate,
    qClub: qClub ? { value: qClub, name: i18n(qClub.toUpperCase()) } : qClub,
    qKnowledgeCategory: qKnowledgeCategory ? { value: qKnowledgeCategory, name: i18n(`SELECT_${qKnowledgeCategory.toUpperCase()}`) } : qKnowledgeCategory,
    type: { value: type, name: i18n(type.toUpperCase()) },
    ...restOfStepOneData,
  },
  stepTwoData: {
    title,
    headline,
    image,
  },
  stepThreeData: content,
  visibleFrom: visibleFrom ? new Date(visibleFrom) : visibleFrom,
});

export { postFormToApi, postApiToForm };
