import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CastOutlinedIcon from '@mui/icons-material/CastOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import i18n from 'utils/i18n';
import {
  CONTENT_TYPES, Q_CLUBS, Q_KNOWLEDGE_CATEGORIES, getClubColor, SPECIAL_EVENTS_COLOR,
} from 'components/entities/post/params';

const Tag = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  padding: `${theme.basicGap}px ${theme.basicGap * 2}px`,
  width: 'fit-content',
  color: '#fff',
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  gap: `${theme.basicGap}px`,
  lineHeight: 1,
}));

const PostTag = ({
  mode, type, qclub, subQClub,
}) => {
  const generateTypeLabel = (contentType) => {
    let icon;
    let label;

    switch (contentType) {
      case CONTENT_TYPES.NEWS:
        icon = <FeedOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('NEWS');
        break;
      case CONTENT_TYPES.EVENT:
        icon = <CastOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('EVENTS');
        break;
      default:
        break;
    }
    return (
      <>
        {icon}
        {label}
      </>
    );
  };

  const getClubLabel = (selectedQclub) => {
    let clubLabel;
    switch (selectedQclub) {
      case Q_CLUBS.KNOWLEDGE:
        clubLabel = i18n('QKNOWLEDGE');
        break;
      case Q_CLUBS.COMMUNITY:
        clubLabel = i18n('QCOMMUNITY');
        break;
      case Q_CLUBS.DIVERSITY:
        clubLabel = i18n('QDIVERSITY');
        break;
      default:
        break;
    }
    return clubLabel;
  };

  const specialEvents = [Q_KNOWLEDGE_CATEGORIES.TALK, Q_KNOWLEDGE_CATEGORIES.TOWNHALL];

  const generateSpecialEventsLabel = (selectedSubQclub) => {
    let icon;
    let label;

    switch (selectedSubQclub) {
      case Q_KNOWLEDGE_CATEGORIES.TALK:
        icon = <ChatBubbleOutlineOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('Q_TALKS');
        break;
      case Q_KNOWLEDGE_CATEGORIES.TOWNHALL:
        icon = <ApartmentOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('TOWNHALL');
        break;
      default:
        break;
    }
    return (
      <>
        {icon}
        {label}
      </>
    );
  };

  const generateKnowledgeLabel = (selectedSubQclub) => {
    let icon;
    let label;

    switch (selectedSubQclub) {
      case Q_KNOWLEDGE_CATEGORIES.TRAINING:
        icon = <SchoolOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('Q_TRAINING');
        break;
      case Q_KNOWLEDGE_CATEGORIES.EDUCATION:
        icon = <SchoolOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('EDUCATION_N_FORMATION');
        break;
      case Q_KNOWLEDGE_CATEGORIES.REPORT:
        icon = <AutoStoriesOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('REPORTS');
        break;
      case Q_KNOWLEDGE_CATEGORIES.EXTEVENT:
        icon = <CalendarMonthOutlinedIcon sx={{ width: '1rem', height: '1rem' }} />;
        label = i18n('EXTERNAL_EVENTS');
        break;
      default:
        break;
    }
    return (
      <>
        {icon}
        {label}
      </>
    );
  };

  return (
    <>
      {mode === 'qclub' && (
        <Tag style={{ backgroundColor: getClubColor(qclub) }}>
          {getClubLabel(qclub)}
        </Tag>
      )}
      {mode === 'type' && (type === CONTENT_TYPES.NEWS || qclub !== Q_CLUBS.KNOWLEDGE) && (
        <Tag style={{ backgroundColor: getClubColor(qclub) }}>
          {generateTypeLabel(type)}
        </Tag>
      )}
      {mode === 'type' && qclub === Q_CLUBS.KNOWLEDGE && specialEvents.includes(subQClub) && (
        <Tag style={{ backgroundColor: SPECIAL_EVENTS_COLOR }}>
          {generateSpecialEventsLabel(subQClub)}
        </Tag>
      )}
      {mode === 'type' && qclub === Q_CLUBS.KNOWLEDGE && type !== CONTENT_TYPES.NEWS && !specialEvents.includes(subQClub) && (
        <Tag style={{ backgroundColor: getClubColor(qclub) }}>
          {generateKnowledgeLabel(subQClub)}
        </Tag>
      )}
    </>
  );
};

PostTag.propTypes = {
  type: PropTypes.string.isRequired,
  qclub: PropTypes.string,
  subQClub: PropTypes.string,
  mode: PropTypes.string,
};

PostTag.defaultProps = {
  qclub: null,
  subQClub: null,
  mode: 'type',
};

export default PostTag;
