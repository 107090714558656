import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Button from 'components/common/button';
import routes from 'routes.json';
import PARAMS from './params';

const HeaderContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  height: `${PARAMS.TOPBAR_CONTENT_HEIGHT}px`,
  justifyContent: 'space-between',
  '.content-container': {
    flex: 1,
  },
}));

const TopBar = ({ children }) => (
  <HeaderContainer>
    <HeaderContainer>
      <div className="content-container">{children}</div>
      <div>
        <ButtonGroup>
          <Link to={`/${routes.SETTINGS}`}>
            <Button variant="menu">
              <SettingsOutlinedIcon fontSize="small" />
            </Button>
          </Link>
        </ButtonGroup>
      </div>
    </HeaderContainer>
  </HeaderContainer>
);

TopBar.propTypes = {
  children: PropTypes.node,
};

TopBar.defaultProps = {
  children: (<></>),
};

export default TopBar;
