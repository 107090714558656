import config from 'config';
import GenericService from './genericService';

class ContentService extends GenericService {
  constructor() {
    super(config.API.CONTENT);
  }
}

export default new ContentService();
