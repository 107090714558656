import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import i18n from 'utils/i18n';
import Paper from 'components/common/paper';
import EditButton from 'components/common/editButton';
import Button from 'components/common/button';
import { getContentKey } from 'utils/content';
import { userCanAdmin } from 'utils/session';
import FormItem from './formItem';

const KeysContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 4}px`,
  '.row': {
    display: 'flex',
    gap: `${theme.basicGap * 4}px`,
  },
  '.item': {
    flex: 1,
  },
  '.inputStyle': {
    '& input,p': {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '1.25rem',
    },
  },
}));

const TitleRow = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const CONTENT_PREFIX = 'about.keys.';

const Keys = ({ data, onSubmit }) => {
  const [isAdminView, setIsAdminView] = useState(false);
  const canAdmin = userCanAdmin();

  const handleEditClick = () => setIsAdminView((prev) => !prev);

  const numerOfCells = 8;
  const initialValues = new Array(numerOfCells).fill(null).map((v, idx) => idx).reduce((prev, idx) => {
    const obj = { ...prev };
    const key = `cell${idx + 1}`;
    obj[key] = getContentKey(data, `${CONTENT_PREFIX}${key}`);
    return obj;
  }, {});

  const validationSchema = Yup.object().shape(
    new Array(numerOfCells).fill(null).map((v, idx) => idx).reduce((prev, idx) => {
      const obj = { ...prev };
      const key = `cell${idx + 1}`;
      obj[key] = Yup.string().required(i18n('MANDATORY'));
      return obj;
    }, {}),
  );

  const wrappedSubmit = (values) => {
    const parsedValues = Object.keys(values)?.reduce((prev, key) => {
      const obj = { ...prev };
      obj[`${CONTENT_PREFIX}${key}`] = values[key];
      return obj;
    }, {});
    onSubmit(parsedValues);
    setIsAdminView(false);
  };

  return (
    <Paper
      elevation={0}
      id="keys"
      sx={{
        border: (canAdmin && isAdminView ? '1px dashed' : 'none'),
        borderColor: 'grey.400',
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={wrappedSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, resetForm }) => (
          <Form>
            <TitleRow>
              <Typography className="medium">{i18n('KEY_FIGURES')}</Typography>
              {canAdmin && (
              <div style={{ display: 'flex', gap: '8px' }}>
                {isAdminView && (
                  <Button variant="light" type="submit">{i18n('SAVE')}</Button>
                )}
                <EditButton
                  variant={isAdminView ? 'disabled' : null}
                  onClick={() => {
                    resetForm({ values: initialValues });
                    handleEditClick();
                  }}
                />
              </div>
              )}
            </TitleRow>
            <br />
            <KeysContainer>
              <div className="row">
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell1" errors={errors} extra={i18n('YEARS')} extraClass="inputStyle" />
                  <Typography dangerouslySetInnerHTML={{
                    __html: i18n('ABOUT_KF_COL1_COPY'),
                  }}
                  />
                </div>
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell2" errors={errors} extraClass="inputStyle" />
                  <Typography dangerouslySetInnerHTML={{
                    __html: i18n('ABOUT_KF_COL2_COPY'),
                  }}
                  />
                </div>
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell3" errors={errors} extraClass="inputStyle" />
                  <Typography dangerouslySetInnerHTML={{
                    __html: i18n('ABOUT_KF_COL3_COPY'),
                  }}
                  />
                </div>
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell4" errors={errors} extraClass="inputStyle" />
                  <Typography dangerouslySetInnerHTML={{
                    __html: i18n('ABOUT_KF_COL4_COPY'),
                  }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell5" errors={errors} extraClass="inputStyle" />
                  <Typography>{i18n('MA_TRANSACTIONS')}</Typography>
                </div>
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell6" errors={errors} extraClass="inputStyle" />
                  <Typography>{i18n('MA_BONDS')}</Typography>
                </div>
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell7" errors={errors} extraClass="inputStyle" />
                  <Typography>{i18n('COUNTRIES_INVESTED')}</Typography>
                </div>
                <div className="item">
                  <FormItem isAdminView={canAdmin && isAdminView} data={data} itemPrefix={CONTENT_PREFIX} itemKey="cell8" errors={errors} extraClass="inputStyle" />
                  <Typography>{i18n('FUNDS_RAISED')}</Typography>
                </div>
              </div>
            </KeysContainer>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

Keys.propTypes = {
  data: PropTypes.array,
  onSubmit: PropTypes.func,
};

Keys.defaultProps = {
  data: [],
  onSubmit: () => {},
};

export default Keys;
