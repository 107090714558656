import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import GridViewIcon from '@mui/icons-material/GridView';
import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/Add';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import AppService from 'services/appService';
import Paper from 'components/common/paper';
import Button from 'components/common/button';
import EditButton from 'components/common/editButton';
import i18n from 'utils/i18n';
import { userCanAdmin } from 'utils/session';
import { useAlert } from 'components/common/alert';
import { getUrl } from 'utils/resource';
import AppDialog from './appDialog';
import CategoryDialog from './categoryDialog';
import { toApi } from './adapter';

const AppsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 3}px`,
  flexWrap: 'wrap',
  '.app-group': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 4}px`,
    height: 'fit-content',
    width: 'calc((100% / 4) - 18px)',
    [theme.breakpoints.down('xl')]: {
      width: 'calc((100% / 3) - 16px)',
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc((100% / 2) - 24px)',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  '.app-card': {
    display: 'flex',
    gap: `${theme.basicGap * 2}px`,
    cursor: 'pointer',
    '.app-name': {
      fontSize: '1.125rem',
    },
    '.app-desc': {
      color: theme.palette.grey[500],
      fontSize: '0.87rem',
    },
    ':hover': {
      '.app-name': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
      },
      '.app-desc': {
        color: theme.palette.grey[600],
      },
      '.icon': {
        visibility: 'visible',
      },
    },
    '.app-img': {
      img: {
        width: '5.125rem',
        aspectRatio: '1/1',
        borderRadius: '8px',
      },
      width: '5.125rem',
    },
    '.icon': {
      visibility: 'hidden',
    },
    '.icon-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.basicGap * 2}px`,
    },
  },
}));

const AppsPage = () => {
  const [data, setData] = useState(null);
  const [appDialog, setAppDialog] = useState({ open: false, loading: false });
  const [categoryDialog, setCategoryDialog] = useState({ open: false });
  const canAdmin = userCanAdmin();
  const showAlert = useAlert();

  const fetchData = async () => {
    const appsCategoryData = await AppService.category.getAll();
    const appsData = await AppService.app.getAll();
    appsCategoryData.data?.forEach((category) => {
      const item = category;
      item.apps = appsData.data?.filter((app) => app.appCategoryId === category.id).sort((a, b) => a.id - b.id);
    });
    setData(appsCategoryData.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openLink = (url) => window.open(url, '_blank');

  const appDialogControl = (open = false, category = null, app = null, loading = false) => setAppDialog({
    open, category, app, loading,
  });

  const categoryDialogControl = (open = false, category = null) => setCategoryDialog({
    open, category,
  });

  const handleAppSubmit = async (values) => {
    setAppDialog({ ...appDialog, loading: true });
    try {
      const adaptedValues = toApi(values);
      if (appDialog.app) {
        await AppService.app.update(appDialog.app.id, adaptedValues);
        showAlert(i18n('UPDATED_SUCCESS', { replacements: { item: i18n('APP') } }));
      } else {
        await AppService.app.create(adaptedValues);
        showAlert(i18n('CREATED_SUCCESS', { replacements: { item: i18n('APP') } }));
      }
      fetchData();
      appDialogControl();
    } catch (error) {
      showAlert(error.message, 'error');
      setAppDialog({ ...appDialog, loading: false });
    }
  };

  const handleAppEdit = (ev, app) => {
    ev.stopPropagation();
    appDialogControl(true, null, app);
  };

  const handleAppDelete = async () => {
    try {
      await AppService.app.delete(appDialog.app.id);
      showAlert(i18n('DELETED_SUCCESS', { replacements: { item: i18n('APP') } }));
      fetchData();
      appDialogControl();
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  const handleCategoryEdit = (category) => {
    categoryDialogControl(true, category);
  };

  const handleCategorySubmit = async (values) => {
    try {
      if (categoryDialog.category) {
        await AppService.category.update(categoryDialog.category.id, values);
        showAlert(i18n('UPDATED_SUCCESS', { replacements: { item: i18n('CATEGORY') } }));
      } else {
        await AppService.category.create(values);
        showAlert(i18n('CREATED_SUCCESS', { replacements: { item: i18n('CATEGORY') } }));
      }
      fetchData();
      categoryDialogControl();
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  const handleCategoryDelete = async () => {
    try {
      await AppService.category.delete(categoryDialog.category.id);
      showAlert(i18n('DELETED_SUCCESS', { replacements: { item: i18n('CATEGORY') } }));
      fetchData();
      categoryDialogControl();
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  return (
    <Page>
      <TopBar><Button variant="breadcrumb-active" startIcon={<GridViewIcon />}>{i18n('CORPORATE_APPS')}</Button></TopBar>
      <div className="page-body">
        <AppsContainer>
          { data && data.map((appGroup, idx) => (
            <Paper elevation={0} className="app-group" key={`apps_group_${idx}`}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className="medium">{appGroup.name}</Typography>
                { canAdmin && (
                <EditButton onClick={() => handleCategoryEdit(appGroup)} />
                )}
              </div>
              { Boolean(appGroup?.apps && appGroup?.apps) && appGroup?.apps?.map(((app, idxApp) => (
                // eslint-disable-next-line
                <div className="app-card" key={`app-card-${idxApp}`} onClick={() => openLink(app.url)}>
                  <div className="app-img">
                    <img src={getUrl(app?.image) || '/img_bg.png'} alt="app's img" loading="lazy" />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography className="app-name">{app.name}</Typography>
                    <Typography className="app-desc">{app.description}</Typography>
                  </div>
                  <div className="icon-container">
                    {canAdmin ? (
                      <EditButton onClick={(ev) => handleAppEdit(ev, app)} />
                    ) : (
                      <LaunchIcon color="secondary" className="icon" fontSize="small" />
                    )}
                  </div>
                </div>
              )))}
              {canAdmin && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography className="medium">{i18n('NEW_APP')}</Typography>
                  <EditButton icon={AddIcon} iconsize="1.6rem" paddingscale={0.5} onClick={() => appDialogControl(true, appGroup)} />
                </div>
              )}
            </Paper>
          ))}
          {canAdmin && (
            <Paper elevation={0} className="app-group">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography className="medium">{i18n('NEW_CATEGORY')}</Typography>
                <EditButton icon={AddIcon} iconsize="1.6rem" paddingscale={0.5} onClick={() => categoryDialogControl(true)} />
              </div>
            </Paper>
          )}
        </AppsContainer>
      </div>
      <AppDialog
        open={appDialog.open}
        isLoading={appDialog.loading}
        app={appDialog.app}
        defaultCategory={appDialog.category}
        onClose={() => appDialogControl()}
        onSubmit={handleAppSubmit}
        onDelete={handleAppDelete}
      />
      <CategoryDialog
        open={categoryDialog.open}
        category={categoryDialog.category}
        onClose={() => categoryDialogControl()}
        onSubmit={handleCategorySubmit}
        onDelete={handleCategoryDelete}
      />
    </Page>
  );
};

export default AppsPage;
