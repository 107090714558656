import AuthService from 'services/authService';
import { USER_ROLES } from 'components/entities/user/params';

const LOCAL_ST_USER_KEY = 'sessionData';
const LOCAL_ST_FLOW_KEY = 'azureLoginFlow';
const COOKIE_KEY = 'JSESSIONID';

const userRoles = USER_ROLES;

const setSessionCookie = (token) => {
  document.cookie = `${COOKIE_KEY}=${token};path=/api`;
};

const cleanSessionCookies = () => {
  document.cookie = '';
};

const setSessionData = (user) => {
  localStorage.setItem(LOCAL_ST_USER_KEY, JSON.stringify(user));
  setSessionCookie(user?.token);
};

const getSessionData = () => {
  const data = localStorage.getItem(LOCAL_ST_USER_KEY);
  return JSON.parse(data);
};

const removeSessionData = () => {
  localStorage.removeItem(LOCAL_ST_USER_KEY);
  cleanSessionCookies();
};

const setAzureFlowData = (data) => {
  localStorage.setItem(LOCAL_ST_FLOW_KEY, JSON.stringify(data));
};

const getAzureFlowData = () => {
  const data = localStorage.getItem(LOCAL_ST_FLOW_KEY);
  return JSON.parse(data);
};

const removeAzureFlowData = () => {
  localStorage.removeItem(LOCAL_ST_FLOW_KEY);
};

const initAzureLogin = async () => {
  const authResponse = await AuthService.getUri();
  setAzureFlowData(authResponse.data.flow);
  window.location.replace(authResponse.data.flow.auth_uri);
};

const doAzureLogin = async (msAuthData) => {
  const clientFlowData = getAzureFlowData();
  try {
    const authResponse = await AuthService.getToken(clientFlowData, msAuthData);
    removeAzureFlowData();
    setSessionData(authResponse.data);
  } catch (error) {
    removeAzureFlowData();
    removeSessionData();
  } finally {
    window.location.replace('/');
  }
};

const handleLogout = () => {
  removeAzureFlowData();
  removeSessionData();
  window.location.replace('/');
};

const userIsAdmin = (user) => user && user.role === userRoles.ADMIN;

const userIsEditor = (user) => user && user.role === userRoles.EDITOR;

const userCanAdmin = () => {
  const sessionData = getSessionData();
  return userIsAdmin(sessionData.user);
};

const userCanEdit = () => {
  const sessionData = getSessionData();
  const isAdmin = userIsAdmin(sessionData?.user);
  const isEditor = userIsEditor(sessionData?.user);
  return isAdmin || isEditor;
};

export {
  setSessionData,
  getSessionData,
  removeSessionData,
  handleLogout,
  userIsAdmin,
  userIsEditor,
  userCanAdmin,
  userCanEdit,
  initAzureLogin,
  doAzureLogin,
  setSessionCookie,
};
