import { useState } from 'react';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Button from 'components/common/button';
import Table from 'components/common/dataTable';
import CountrySelect from 'components/entities/country/select';
import OfficeSelect from 'components/entities/office/select';
import DepartmentSelect from 'components/entities/department/select';
import StateSelect from 'components/entities/user/stateSelect';
import RoleSelect from 'components/entities/user/roleSelect';
import TranslateCode from 'components/common/translate';
import EditButton from 'components/common/editButton';
import { useAlert } from 'components/common/alert';
import UserService from 'services/userService';
import i18n from 'utils/i18n';
import routes from 'routes.json';
import { userCanAdmin } from 'utils/session';
import { getUrl } from 'utils/resource';
import UserDialog from './userDialog';
import SyncDialog from './syncDialog';

// AUX COMPONENTS
const TopbarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap}px`,
}));

const TableSummaryContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 4}px`,
  paddingBottom: `${theme.basicGap * 4}px`,
  div: {
    textAlign: 'left',
  },
  '& p:last-child': {
    color: theme.palette.grey.special,
  },
  '.number': {
    fontSize: '1.125rem',
    fontWeight: 500,
  },
}));

const TableSummary = ({ data }) => (
  <TableSummaryContainer>
    <div>
      <Typography className="number">{data?.summary?.countUsers || '-'}</Typography>
      <Typography>{i18n('EMPLOYEES')}</Typography>
    </div>
    <div>
      <Typography className="number">
        {data?.summary?.countOffices || '-'}
      </Typography>
      <Typography>{i18n('OFFICES')}</Typography>
    </div>
    <div style={{ textAlign: 'left' }}>
      <Typography className="number">{data?.summary?.countDepartments || '-'}</Typography>
      <Typography>{i18n('DEPARTMENTS')}</Typography>
    </div>
  </TableSummaryContainer>
);

TableSummary.propTypes = {
  data: PropTypes.any.isRequired,
};

const RenderState = ({ cell }) => {
  const colorStyles = {};

  switch (cell) {
    case 'pending':
      colorStyles.color = 'orange';
      break;
    case 'accepted':
      colorStyles.color = 'green';
      break;
    case 'rejected':
      colorStyles.color = 'red';
      break;
    default:
  }

  return (
    <span style={colorStyles}>
      <TranslateCode cell={cell} />
    </span>
  );
};

RenderState.propTypes = {
  cell: PropTypes.any.isRequired,
};

const RenderNameWithPic = ({ image, name }) => (
  <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
    {name && (
      <>
        <Avatar sx={{ width: 24, height: 24 }} className="avatar" src={getUrl(image)} imgProps={{ loading: 'lazy' }} />
        {name}
      </>
    )}
  </div>
);

RenderNameWithPic.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const RenderUserName = ({ row }) => (<RenderNameWithPic image={row?.azureProfilePhoto} name={row?.azureName} />);

RenderUserName.propTypes = {
  row: PropTypes.any.isRequired,
};

const RenderManagerName = ({ row }) => (<RenderNameWithPic image={row?.azureManager?.azureProfilePhoto} name={row?.azureManager?.azureName} />);

RenderManagerName.propTypes = {
  row: PropTypes.any.isRequired,
};

// PAGE
const TeamPage = () => {
  const dataPromise = UserService.getAllPaginated;
  const navigate = useNavigate();
  const [isAdminView, setIsAdminView] = useState(false);
  const canAdmin = userCanAdmin();
  const [userDialog, setUserDialog] = useState({
    userId: null,
    open: false,
  });
  const [tableUpdate, setTableUpdate] = useState({ callback: null });
  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
  const showAlert = useAlert();

  const tableHeader = [
    { display: i18n('NAME'), property: 'azureName', customComponent: RenderUserName },
    { display: i18n('JOB_DESC'), property: 'azureJob' },
    { display: i18n('DEPARTMENT'), property: 'azureDepartment' },
    { display: i18n('MANAGER'), property: 'azureManager.azureName', customComponent: RenderManagerName },
    { display: i18n('OFFICE'), property: 'azureOffice.name' },
    { display: i18n('COUNTRY'), property: 'azureCountry.name' },
    { display: i18n('START_DATE'), property: 'azureHireDate' },
  ];

  const tableHeaderAdmin = [
    { display: i18n('STATE'), property: 'allowed', customComponent: RenderState },
    { display: i18n('ROLE'), property: 'role', customComponent: TranslateCode },
  ];

  const filters = [{
    name: 'countryIds',
    Component: CountrySelect,
    componentProps: {
      multiple: true,
      limitTags: 0,
      textFieldVariant: 'outlined',
      disableCloseOnSelect: true,
      placeholder: i18n('COUNTRIES'),
      size: 'small',
      sx: { minWidth: '160px' },
      disableClearable: true,
      renderTags: (value) => (
        <>
          +
          {value.length}
        </>
      ),
    },
    parseValue: (value) => value.id,
  }, {
    name: 'officeIds',
    Component: OfficeSelect,
    componentProps: {
      multiple: true,
      limitTags: 0,
      textFieldVariant: 'outlined',
      disableCloseOnSelect: true,
      placeholder: i18n('OFFICES'),
      size: 'small',
      sx: { minWidth: '140px' },
      disableClearable: true,
      renderTags: (value) => (
        <>
          +
          {value.length}
        </>
      ),
    },
    parseValue: (value) => value.id,
  }, {
    name: 'departments',
    Component: DepartmentSelect,
    componentProps: {
      multiple: true,
      limitTags: 0,
      textFieldVariant: 'outlined',
      disableCloseOnSelect: true,
      placeholder: i18n('DEPARTMENTS'),
      size: 'small',
      sx: { minWidth: '190px' },
      disableClearable: true,
      renderTags: (value) => (
        <>
          +
          {value.length}
        </>
      ),
    },
    parseValue: (value) => value.id,
  }];

  const adminFilters = [{
    name: 'allowed',
    Component: StateSelect,
    componentProps: {
      multiple: true,
      limitTags: 0,
      textFieldVariant: 'outlined',
      disableCloseOnSelect: true,
      placeholder: i18n('STATE'),
      size: 'small',
      sx: { minWidth: '140px' },
      disableClearable: true,
      renderTags: (value) => (
        <>
          +
          {value.length}
        </>
      ),
    },
    parseValue: (value) => value.id,
  }, {
    name: 'roles',
    Component: RoleSelect,
    componentProps: {
      multiple: true,
      limitTags: 0,
      textFieldVariant: 'outlined',
      disableCloseOnSelect: true,
      placeholder: i18n('ROLE'),
      size: 'small',
      sx: { minWidth: '130px' },
      disableClearable: true,
      renderTags: (value) => (
        <>
          +
          {value.length}
        </>
      ),
    },
    parseValue: (value) => value.id,
  }];

  const adminViewToggle = () => {
    setIsAdminView((prev) => !prev);
  };

  const userDialogControl = (open = false, userId = null) => setUserDialog({ open, userId });

  const onRowClick = (row) => {
    if (canAdmin && isAdminView) {
      userDialogControl(true, row.id);
    } else {
      navigate(`/${routes.USER.replace(':id', row.id)}`);
    }
  };

  const tableOptions = [{
    icon: EditButton,
    handleClick: onRowClick,
    title: 'Edit',
  }];

  const handleDialogClose = () => {
    userDialogControl();
  };

  const handleDialogSave = async (values) => {
    try {
      await UserService.update(userDialog.userId, values);
      if (tableUpdate.callback) {
        tableUpdate.callback();
      }
      userDialogControl();
      showAlert(i18n('UPDATED_SUCCESS', { replacements: { item: i18n('USER') } }));
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  const handleSyncClick = async () => {
    setIsSyncDialogOpen(true);
    try {
      await UserService.adSync();
      if (tableUpdate.callback) {
        tableUpdate.callback();
      }
      showAlert(i18n('UPDATED_SUCCESS', { replacements: { item: i18n('USERS') } }));
    } catch (error) {
      showAlert(error.message, 'error');
    } finally {
      setIsSyncDialogOpen(false);
    }
  };

  return (
    <Page>
      <TopBar>
        <TopbarContainer>
          <Button variant="breadcrumb-active" startIcon={<GroupOutlinedIcon />}>{i18n('PEOPLE_FROM_QUALITAS')}</Button>
          {canAdmin && (
            <>
              <Button
                variant={isAdminView ? 'breadcrumb-active' : 'dark'}
                startIcon={<ManageAccountsOutlinedIcon />}
                onClick={!isAdminView ? adminViewToggle : () => {}}
              >
                {i18n('MANAGER_USERS')}
              </Button>
              {isAdminView && (
                <>
                  <Button
                    variant="dark"
                    startIcon={<SyncOutlinedIcon />}
                    onClick={handleSyncClick}
                  >
                    {i18n('SYNCRONIZE')}
                  </Button>
                  <Button
                    variant="light"
                    onClick={adminViewToggle}
                  >
                    {i18n('EXIT')}
                  </Button>
                </>
              )}
            </>
          )}
        </TopbarContainer>
      </TopBar>
      <div className="page-body">
        <Table
          dataPromise={dataPromise}
          header={(canAdmin && isAdminView) ? [...tableHeader, ...tableHeaderAdmin] : tableHeader}
          filters={(canAdmin && isAdminView) ? [...filters, ...adminFilters] : filters}
          handleClick={onRowClick}
          summaryComponent={TableSummary}
          rowOptions={(canAdmin && isAdminView) ? tableOptions : null}
          headerStyle={isAdminView
            ? { backgroundColor: 'primary.dark', color: '#fff' }
            : { backgroundColor: 'primary.light', color: 'primary.main' }}
          setUpdateCallback={(f) => {
            setTableUpdate({ callback: f });
          }}
          findInputPlaceholder={i18n('FIND_PERSON')}
        />
      </div>
      { userDialog.userId && (
        <UserDialog
          open={userDialog.open}
          onSave={handleDialogSave}
          onClose={handleDialogClose}
          id={userDialog.userId}
        />
      )}
      <SyncDialog open={isSyncDialogOpen} />
    </Page>
  );
};

export default TeamPage;
