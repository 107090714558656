import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

// A custom theme for this app
const theme = createTheme({
  palette: {
    text: {
      primary: '#000037',
    },
    primary: {
      main: '#000037',
      light: '#E5EEF8',
    },
    secondary: {
      main: '#0057B8',
      dark: '#000037',
    },
    error: {
      main: red[700],
    },
    action: {
      hover: '#E5EEF8',

    },
    grey: {
      special: '#909090',
    },
  },
  typography: {
    fontFamily: '"Inter","Helvetica","Arial",sans-serif',
    lineHeight: 1.3,
  },
  basicGap: 8,
  // spacing: 8,
  esES,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      qualitas: 1281,
    },
  },
});

export default theme;
