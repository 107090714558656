import { useRef } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { styled } from '@mui/material/styles';
import { getUrl } from 'utils/resource';

const InputContainer = styled('div')(({ width, aspectRatio, theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 2}px`,
  '.button': {
    backgroundColor: theme.palette.grey[300],
    width,
    aspectRatio,
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    border: `1px dashed ${theme.palette.primary.main}`,
    padding: '1px',
    overflow: 'hidden',
    img: {
      width: '100%',
      borderRadius: '8px',
    },
    ':hover': {
      img: {
        display: 'none',
      },
      '.change-icn': {
        display: 'block',
      },
    },
    '.icon': {
      '.MuiSvgIcon-root': {
        width: '34px',
        height: '34px',
      },
      display: 'flex',
      alignItems: 'center',
    },
    '.change-icn': {
      display: 'none',
    },
  },
  '.error': {
    color: theme.palette.error.main,
  },
}));

const ImageInput = ({
  value, onChange, error, helperText, width, aspectRatio, ...props
}) => {
  const fileInput = useRef(null);
  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <InputContainer width={width} aspectRatio={aspectRatio}>
      {/* eslint-disable-next-line */}
      <div
        className="button"
        onClick={handleClick}
      >
        {value ? (
          <>
            <img src={value instanceof File ? URL.createObjectURL(value) : getUrl(value)} alt="app" />
            <div className="icon">
              <SyncOutlinedIcon color="primary" className="change-icn" />
            </div>
          </>
        ) : (
          <div className="icon">
            <AddIcon fontSize="large" color="primary" />
          </div>
        )}
      </div>
      <div className="error">{helperText}</div>
      <input
        ref={fileInput}
        accept="image/*"
        type="file"
        style={{ display: 'none' }}
        onChange={(ev) => onChange(ev.target.files[0] || null)}
        {...props}
      />
    </InputContainer>
  );
};

ImageInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  width: PropTypes.string,
  aspectRatio: PropTypes.string,
};

ImageInput.defaultProps = {
  value: null,
  onChange: null,
  error: false,
  helperText: null,
  width: '82px',
  aspectRatio: '1/1',
};

export default ImageInput;
