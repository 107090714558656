import DataSelect from 'components/common/dataSelect';
import i18n from 'utils/i18n';
import { CONTENT_TYPES } from './params';

const ContentTypeSelect = (props) => {
  const data = Object.keys(CONTENT_TYPES).map((key) => ({ value: CONTENT_TYPES[key], name: i18n(CONTENT_TYPES[key].toUpperCase()) }));

  return (
    <DataSelect
      dataObject={data}
      itemLabelPath="name"
      itemValuePath="value"
      {...props}
    />
  );
};

export default ContentTypeSelect;
