// remove null / '' / undefined properties from object
const removeNull = (obj) => {
  const newObj = {};
  Object.keys(obj)?.forEach((prop) => {
    if (obj[prop] || obj[prop] === false) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

// convert empty strings '' to null
const emptyToNull = (obj) => {
  const newObj = {};
  Object.keys(obj)?.forEach((prop) => {
    newObj[prop] = obj[prop] === '' ? null : obj[prop];
  });
  return newObj;
};

// check if some prop is empty
const checkIfempty = (obj, props) => props.some((prop) => !obj[prop]);

export { removeNull, emptyToNull, checkIfempty };
