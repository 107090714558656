import DataSelect from 'components/common/dataSelect';
import ConfigService from 'services/configService';

const DepartmentSelect = (props) => {
  const dataPromise = async () => {
    const deparments = await ConfigService.getOne('department');
    deparments.data = deparments.data?.map(({ name }) => ({ name, id: name }));
    return deparments;
  };

  return (
    <DataSelect
      dataPromise={dataPromise}
      itemLabelPath="name"
      itemValuePath="id"
      {...props}
    />
  );
};

export default DepartmentSelect;
