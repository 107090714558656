import { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import TopBar from 'components/common/appLayout/topbar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InView } from 'react-intersection-observer';
import { debounce } from 'lodash';
import BreadCrumb from 'components/common/breadcrumb';
import Page from 'components/common/page';
import { useAlert } from 'components/common/alert';
import ContentService from 'services/contentService';
import i18n from 'utils/i18n';
import TimeLine from './timeline';
import InfoGrid from './infogrid';
import Keys from './keys';
import Energies from './energies';
import Map from './map';
import Partners from './partners';
import Reports from './reports';

const CustomDivider = () => (<div style={{ padding: '24px 0px' }}><Divider /></div>);

const breadCrumbData = [{
  icon: <InfoOutlinedIcon />,
  label: 'ABOUT_QE_LONG',
  scrollTo: '#infogrid',
}, {
  label: 'TIMELINE',
  scrollTo: '#timeline',
}, {
  label: 'KEY_FIGURES',
  scrollTo: '#keys',
}, {
  label: 'PORTFOLIO',
  scrollTo: '#energies',
}, {
  label: 'MAP',
  scrollTo: '#map',
}, {
  label: 'BOARDS',
  scrollTo: '#partners',
}, {
  label: 'ESG',
  scrollTo: '#reports',
}];

// debounced side effect when a observed element is in viewport, declared outside for avoiding function redeclare at every render
const debouncedSelectCrumb = debounce((idx, setSelectedCrumb) => {
  setSelectedCrumb(idx);
}, 300);

const AboutPage = () => {
  const [selectedCrumb, setSelectedCrumb] = useState(0);
  const [data, setData] = useState(null);
  const showAlert = useAlert();

  const fetchData = async () => {
    try {
      const pageContent = await ContentService.getAll({ filter: 'about' });
      const keys = pageContent.data?.filter((record) => record?.code?.includes('keys'));
      const energy = pageContent.data?.filter((record) => record?.code?.includes('energy'));
      setData({ keys, energy });
    } catch (error) {
      setData(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const observeVisibility = (inView, entry, idx) => {
    if (inView) {
      debouncedSelectCrumb(idx, setSelectedCrumb);
    }
  };

  const handleKeysFormSubmit = async (values) => {
    try {
      const newData = await ContentService.create(values);
      setData({ ...data, keys: newData.data });
      showAlert(i18n('UPDATED_SUCCESS', { replacements: { item: i18n('PAGE') } }));
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  const handleEnergiesFormSubmit = async (values) => {
    try {
      const newData = await ContentService.create(values);
      setData({ ...data, energy: newData.data });
      showAlert(i18n('UPDATED_SUCCESS', { replacements: { item: i18n('PAGE') } }));
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  return (
    <Page>
      <TopBar>
        <BreadCrumb breadCrumbData={breadCrumbData} selected={selectedCrumb} />
      </TopBar>
      <div className="page-body">
        <InView as="div" onChange={(inView, entry) => observeVisibility(inView, entry, 0)} threshold={1}>
          <InfoGrid />
        </InView>
        <CustomDivider />
        <InView as="div" onChange={(inView, entry) => observeVisibility(inView, entry, 1)} threshold={1}>
          <TimeLine />
        </InView>
        <CustomDivider />
        <InView as="div" onChange={(inView, entry) => observeVisibility(inView, entry, 2)} threshold={1}>
          {data && (<Keys data={data.keys} onSubmit={handleKeysFormSubmit} />)}
        </InView>
        <CustomDivider />
        <InView as="div" onChange={(inView, entry) => observeVisibility(inView, entry, 3)} threshold={1}>
          {data && (<Energies data={data.energy} onSubmit={handleEnergiesFormSubmit} />)}
        </InView>
        <CustomDivider />
        <InView as="div" onChange={(inView, entry) => observeVisibility(inView, entry, 4)} threshold={0.8}>
          <Map />
        </InView>
        <CustomDivider />
        <InView as="div" onChange={(inView, entry) => observeVisibility(inView, entry, 5)} threshold={0.6}>
          <Partners />
        </InView>
        <CustomDivider />
        <InView as="div" onChange={(inView, entry) => observeVisibility(inView, entry, 6)} threshold={0.5}>
          <Reports />
        </InView>
      </div>
    </Page>
  );
};

export default AboutPage;
