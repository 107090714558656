import config from 'config';
import cfetch from 'utils/fetch';

class GenericService {
  #endpointPath;

  constructor(endpointPath) {
    this.#endpointPath = `${config.API.BASE_URL}/${endpointPath}`;
    this.getAll = this.getAll.bind(this);
    this.getAllPaginated = this.getAllPaginated.bind(this);
    this.getOne = this.getOne.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.filtersToQs = GenericService.filtersToQs.bind(this);
    this.genericReq = this.genericReq.bind(this);
    this.genericReqMultipart = this.genericReqMultipart.bind(this);
  }

  static filtersToQs(currentUrl, filters = {}) {
    let url = `${currentUrl}?`;
    Object.keys(filters)?.forEach((filterKey) => {
      if (filters[filterKey] && Array.isArray(filters[filterKey])) {
        url = filters[filterKey].reduce((prev, value) => (`${prev}&${filterKey}=${value}`), url);
      } else if (filters[filterKey] && !Array.isArray(filters[filterKey])) {
        url = `${url}&${filterKey}=${filters[filterKey]}`;
      }
    });
    return url;
  }

  async getAll(filters) {
    const url = this.filtersToQs(this.#endpointPath, filters);

    const response = await cfetch(url);
    const responseData = await response.json();

    return responseData;
  }

  async getAllPaginated({
    find = null, page = 0, pageSize = 10, ...extraFilters
  } = {}) {
    const url = this.filtersToQs(this.#endpointPath, {
      filter: find || '', page: page + 1, perPage: pageSize, ...extraFilters,
    });

    const response = await cfetch(url);
    const responseData = await response.json();

    return responseData;
  }

  async getOne(id) {
    const url = `${this.#endpointPath}/${id}`;
    const response = await cfetch(url);
    const responseData = await response.json();

    return responseData;
  }

  async create(data) {
    const url = this.#endpointPath;
    const response = await cfetch(
      url,
      { method: 'POST', body: JSON.stringify(data), headers: { 'content-type': 'application/json' } },
    );
    const responseData = await response.json();

    return responseData;
  }

  async update(id, data) {
    const url = `${this.#endpointPath}/${id}`;
    const response = await cfetch(
      url,
      { method: 'PUT', body: JSON.stringify(data), headers: { 'content-type': 'application/json' } },
    );
    const responseData = await response.json();

    return responseData;
  }

  async delete(id) {
    const url = `${this.#endpointPath}/${id}`;
    const response = await cfetch(
      url,
      { method: 'DELETE', headers: { 'content-type': 'application/json' } },
    );
    const responseData = await response.json();

    return responseData;
  }

  async genericReq({ urlExtra = '', ...options }) {
    const url = `${this.#endpointPath}${urlExtra}`;
    const response = await cfetch(
      url,
      { ...options, headers: { 'content-type': 'application/json' } },
    );
    const responseData = await response.json();

    return responseData;
  }

  async genericReqMultipart(data, { urlExtra = '', ...options }) {
    const url = `${this.#endpointPath}${urlExtra}`;

    const body = new FormData();
    Object.keys(data)?.forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((v) => body.append(key, v));
      } else {
        body.append(key, data[key]);
      }
    });

    const response = await cfetch(
      url,
      { body, ...options },
    );
    const responseData = await response.json();

    return responseData;
  }
}

export default GenericService;
