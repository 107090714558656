import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Button from 'components/common/button';
import i18n from 'utils/i18n';
import OfficeSelect from 'components/entities/office/select';
import EventDateFilter from 'components/entities/post/eventDateFilter';
import { CONTENT_TYPES, NO_QCLUB } from 'components/entities/post/params';
import PostPage from './postPage';
import PageHeader from './pageHeader';

const EventsPage = () => {
  const pageFilters = [
    {
      Component: EventDateFilter,
      placeholder: i18n('DATE'),
      name: 'date',
    },
    {
      Component: OfficeSelect,
      placeholder: i18n('OFFICE'),
      name: 'office',
    },
  ];

  const otherFilters = [{ name: 'qClub', value: NO_QCLUB }];

  return (
    <Page>
      <TopBar>
        <PageHeader>
          <Button variant="breadcrumb-active" startIcon={<FeedOutlinedIcon />}>{i18n('QE_EVENTS')}</Button>
        </PageHeader>
      </TopBar>
      <div className="page-body">
        <PostPage type={CONTENT_TYPES.EVENT} visualFiltersData={pageFilters} otherFiltersData={otherFilters} />
      </div>
    </Page>
  );
};

export default EventsPage;
