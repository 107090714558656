import PropTypes from 'prop-types';
import i18n from 'utils/i18n';

const TranslateCode = ({ cell }) => (
  <>
    {i18n(cell?.toUpperCase())}
  </>
);

TranslateCode.propTypes = {
  cell: PropTypes.string.isRequired,
};

export default TranslateCode;
