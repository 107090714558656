import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Button from 'components/common/button';
import Paper from 'components/common/paper';
import OfficeSelect from 'components/entities/office/select';
import EventDateFilter from 'components/entities/post/eventDateFilter';
import {
  Q_CLUBS, CONTENT_TYPES, getClubColor, ALL_QCLUBS,
} from 'components/entities/post/params';
import i18n from 'utils/i18n';
import routes from 'routes.json';
import QknowledgeMenu from './qknowledgeMenu';
import PostPage from './postPage';
import Breadcrumb from './breadcrumb';
import PageHeader from './pageHeader';

const QClubContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 3}px`,
}));

/**
 * Renders q-club posts pages
 */
const QclubsPage = () => {
  const pathParams = useParams();
  const navigate = useNavigate();

  const [type, setType] = useState(null);
  const [qClub, setQClub] = useState(null);
  const [qknowledgeView, setQknowledgeView] = useState(null);
  const [qknowledgeCategory, setQknowledgeCategory] = useState(null);
  const [eventFilter, setEventFilter] = useState(null);

  // filters for view's content
  const setFilters = (selectedQclub = null, selectedQknowCategory = null, eventDate = null) => {
    if (selectedQclub !== qClub) {
      setQClub(selectedQclub);
    }

    if (selectedQknowCategory !== qknowledgeCategory) {
      setQknowledgeCategory(selectedQknowCategory);
    }

    if (eventDate !== eventFilter) {
      setEventFilter(eventDate);
    }
  };

  // selected views inside a qclub
  const setDisplay = (selectedType, selectedQknowView = null) => {
    if (type !== selectedType) {
      setType(selectedType);
    }

    if (qknowledgeView !== selectedQknowView) {
      setQknowledgeView(selectedQknowView);
    }
  };

  useEffect(() => {
    const newQclub = pathParams?.qClub || ALL_QCLUBS;
    const newQknowledgeCategory = pathParams?.subQclub || null;
    if ((newQclub !== qClub) || (newQknowledgeCategory !== qknowledgeCategory)) {
      const newType = newQclub !== Q_CLUBS.KNOWLEDGE ? CONTENT_TYPES.NEWS : null;
      setDisplay(newType);
      setFilters(newQclub, newQknowledgeCategory);
    }
  }, [pathParams]);

  // page config depending on qclub, buttons, filters, copies...
  const generatePageButtons = (selectedQclub) => {
    let pageButtons = [
      (props) => (
        <Button
          variant={(type === CONTENT_TYPES.NEWS && qknowledgeView === null) ? 'menu2-active' : 'menu2'}
          onClick={() => {
            setDisplay(CONTENT_TYPES.NEWS);
            setFilters(qClub, null, null);
          }}
          {...props}
        >
          <Typography className="medium">{i18n('LATEST_NEWS')}</Typography>
        </Button>
      )];

    if (selectedQclub === ALL_QCLUBS) {
      pageButtons.push((props) => (
        <Button
          variant={type === CONTENT_TYPES.EVENT ? 'menu2-active' : 'menu2'}
          onClick={() => {
            setDisplay(CONTENT_TYPES.EVENT);
            setFilters(qClub, null, 'upcoming');
          }}
          {...props}
        >
          <Typography className="medium">{i18n('UPCOMING_EVENTS')}</Typography>
        </Button>
      ));
    } else if (selectedQclub === Q_CLUBS.KNOWLEDGE) {
      pageButtons = [
        (props) => (
          <Button
            variant={(type === null && qknowledgeView === null) ? 'menu2-active' : 'menu2'}
            onClick={() => {
              setDisplay(null);
              setFilters(qClub, null, null);
            }}
            {...props}
          >
            <Typography className="medium">{i18n('LATEST_NEWS')}</Typography>
          </Button>
        )];
      pageButtons.push((props) => (
        <Button
          variant={qknowledgeView === 0 ? 'menu2-active' : 'menu2'}
          onClick={() => {
            setDisplay(type, 0);
          }}
          {...props}
        >
          <Typography className="medium">{i18n('INTERNAL_RESOURCES')}</Typography>
        </Button>
      ));
      pageButtons.push((props) => (
        <Button
          variant={qknowledgeView === 1 ? 'menu2-active' : 'menu2'}
          onClick={() => {
            setDisplay(type, 1);
          }}
          {...props}
        >
          <Typography className="medium">{i18n('EXTERNAL_RESOURCES')}</Typography>
        </Button>
      ));
    } else {
      pageButtons.push((props) => (
        <Button
          variant={(type === CONTENT_TYPES.EVENT && !qknowledgeView) ? 'menu2-active' : 'menu2'}
          onClick={() => {
            setDisplay(CONTENT_TYPES.EVENT);
            setFilters(qClub, null, null);
          }}
          {...props}
        >
          <Typography className="medium">{i18n('EVENTS')}</Typography>
        </Button>
      ));
    }
    return pageButtons;
  };

  const topMessages = {};
  topMessages[ALL_QCLUBS] = 'Q_CLUBS_HOME_COPY';

  const bottomMessages = {};
  bottomMessages[Q_CLUBS.KNOWLEDGE] = 'Q_CLUBS_KNOWLEDGE_COPY';
  bottomMessages[Q_CLUBS.COMMUNITY] = 'Q_CLUBS_COMMUNITY_COPY';
  bottomMessages[Q_CLUBS.DIVERSITY] = 'Q_CLUBS_DIVERSITY_COPY';

  const generateMessageBox = (selectedQclub, messageList) => {
    const messageCode = messageList[selectedQclub];

    return (
      <>
        { messageCode && (
        <Paper
          elevation={0}
          dangerouslySetInnerHTML={{
            __html: i18n(messageCode),
          }}
          sx={{
            backgroundColor: selectedQclub !== ALL_QCLUBS ? getClubColor(selectedQclub) : '#fff',
            color: selectedQclub !== ALL_QCLUBS ? '#fff' : 'inherit',
            padding: '16px',
            textAlign: selectedQclub !== ALL_QCLUBS ? 'center' : 'justify',
          }}
        />
        )}
      </>
    );
  };

  const commonOfficeFilter = {
    Component: OfficeSelect,
    placeholder: i18n('OFFICE'),
    name: 'office',
  };

  const commonEventFilter = {
    Component: EventDateFilter,
    placeholder: i18n('DATE'),
    name: 'date',
  };

  const pageFilters = {};
  pageFilters[CONTENT_TYPES.NEWS] = [commonOfficeFilter];
  pageFilters[CONTENT_TYPES.EVENT] = [commonEventFilter, commonOfficeFilter];

  const generatePageFilters = (selectedQclub, contentType) => (selectedQclub !== ALL_QCLUBS ? pageFilters[contentType] : []);

  const handleGoBack = () => {
    navigate(`/${routes.QCLUBS.replace(':qClub?', qClub || '').replace(':subQclub?', '')}`);
  };

  const generateContentFilters = (selectedQclub, selectedQknowCategory, eventDate) => {
    const generatedFilters = [
      {
        name: 'qClub', value: selectedQclub,
      }, {
        name: 'qKnowledgeCategory', value: selectedQknowCategory,
      },
    ];

    if (eventDate) {
      generatedFilters.push({
        name: 'date', value: { value: eventDate },
      });
    }

    return generatedFilters;
  };

  return (
    <Page>
      <TopBar>
        <PageHeader qClub={qClub && qClub !== ALL_QCLUBS ? qClub : null}>
          {qClub && (<Breadcrumb type={type} selectedQclub={qClub} />)}
        </PageHeader>
      </TopBar>
      <div className="page-body">
        <QClubContainer>
          {generateMessageBox(qClub, topMessages)}
          {qClub && (
            <PostPage
              type={type}
              visualFiltersData={generatePageFilters(qClub, type)}
              otherFiltersData={generateContentFilters(qClub, qknowledgeCategory, eventFilter)}
              buttons={qknowledgeCategory === null ? generatePageButtons(qClub) : null}
              buttonsOnly={qknowledgeView !== null && qknowledgeCategory === null}
              onGoBack={qknowledgeCategory !== null ? handleGoBack : null}
            />
          )}
          {qClub === Q_CLUBS.KNOWLEDGE && qknowledgeView !== null && qknowledgeCategory === null && (
            <QknowledgeMenu viewType={qknowledgeView} />
          )}
          {generateMessageBox(qClub, bottomMessages)}
        </QClubContainer>
      </div>
    </Page>
  );
};

export default QclubsPage;
