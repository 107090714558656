import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import SideBar from './sidebar';

const LayoutContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
}));

const AppLayout = () => (
  <>
    <LayoutContainer>
      <SideBar />
      <Outlet />
    </LayoutContainer>
  </>
);

export default AppLayout;
