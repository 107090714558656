import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import i18n from 'utils/i18n';
import Paper from 'components/common/paper';
import UserService from 'services/userService';
import routes from 'routes.json';

const PartnersContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: `${theme.basicGap * 3}px`,
  justifyContent: 'flex-start',
  '.card': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 2}px`,
    width: '12.3rem',
    img: {
      width: '12.3rem',
    },
    cursor: 'pointer',
    ': hover': {
      '.title': {
        textDecoration: 'underline',
      },
    },
  },
}));

const PartnerCard = ({
  name, companyRole, city, img, onClick,
}) => (
  <div className="card" onClick={onClick}>
    <img src={img} alt="partner" loading="lazy" />
    <Typography className="medium title" sx={{ fontSize: '1.12rem' }}>{name}</Typography>
    <div>
      <Typography className="medium" color="secondary">{companyRole}</Typography>
      <Typography color="secondary">{city}</Typography>
    </div>
  </div>
);

PartnerCard.propTypes = {
  name: PropTypes.string.isRequired,
  companyRole: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const partnersData = [{
  name: 'Iñigo Olaguíbel',
  companyRole: 'Founding Partner & CEO',
  city: 'Madrid',
  img: '/partner_inigo.png',
  matchBy: 'inigo.olaguibel@qenergy.com',
}, {
  name: 'Daniel Parejo',
  companyRole: 'Partner & CIO',
  city: 'Madrid',
  img: '/partner_daniel.png',
  matchBy: 'daniel.parejo@qenergy.com',
}, {
  name: 'Óscar Pérez',
  companyRole: 'Partner & CBDO',
  city: 'Madrid',
  img: '/partner_oscar.png',
  matchBy: 'oscar.perez@qenergy.com',
}, {
  name: 'Vicente Asuero',
  companyRole: 'Partner & Investor Relations',
  city: 'Madrid',
  img: '/partner_vicente.png',
  matchBy: 'vicente.asuero@qenergy.com',
}, {
  name: 'Pablo Giménez',
  companyRole: 'Partner & Head of Develpment',
  city: 'Madrid',
  img: '/partner_pablo.png',
  matchBy: 'pablo.gimenez@qenergy.com',
}, {
  name: 'Jose Ramón Fernandez',
  companyRole: 'Partner, CFO & Head of QERS',
  city: 'Madrid',
  img: '/partner_jose_ramon.png',
  matchBy: 'joseramon.fernandez@qenergy.com',
}, {
  name: 'Manuel Espinosa',
  companyRole: 'Partner & Investment & Financing',
  city: 'Madrid',
  img: '/partner_manuel.png',
  matchBy: 'manuel.espinosa@qenergy.com',
}, {
  name: 'Alejandro Ciruelos',
  companyRole: 'Partner & Country Head UK',
  city: 'London',
  img: '/partner_alejandro.png',
  matchBy: 'alejandro.ciruelos@qenergy.com',
}, {
  name: 'Enrico Orsenigo',
  companyRole: 'Partner & Country Head Italy',
  city: 'Milan',
  img: '/partner_enrico.png',
  matchBy: 'enrico.orsenigo@qenergy.com',
}];

const Partners = () => {
  const [partners, setPartners] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const usersData = await UserService.getByEmails(partnersData.map((partner) => partner.matchBy));
      const matchedData = partnersData.map((partner) => {
        const backendData = usersData.data?.find((userInfo) => userInfo.azureEmail === partner.matchBy);
        return {
          ...partner,
          userId: backendData?.id,
        };
      });
      setPartners(matchedData);
    } catch (error) {
      setPartners(partnersData);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper elevation={0} id="partners">
      <Typography className="medium">{i18n('PARTNERS_BOARD')}</Typography>
      <br />
      <PartnersContainer>
        {
          partners && partners.map((partner, idx) => (
            <PartnerCard
              {...partner}
              key={`partner_${idx}`}
              onClick={() => navigate(`/${routes.USER.replace(':id', partner?.userId)}`)}
            />
          ))
        }
      </PartnersContainer>
    </Paper>
  );
};

export default Partners;
