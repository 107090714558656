import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import Paper from 'components/common/paper';
import NewsCategorySelect from 'components/entities/post/newsCategorySelect';
import OfficeSelect from 'components/entities/office/select';
import Button from 'components/common/button';
import i18n from 'utils/i18n';
import AutoSubmit from 'components/common/autosubmit';

/**
 * Renders the form for content type news
 */
const NewsForm = ({
  goBack, goNext, onSubmit, editing, header, editionMode,
}) => {
  const initialValues = {
    newsCategory: editing?.newsCategory || null,
    offices: editing?.offices || [],
    audienceOffices: editing?.audienceOffices || [],
  };

  const validationSchema = Yup.object().shape({
    newsCategory: Yup.object().required(i18n('MANDATORY')),
  });

  const wrappedGoNext = async (validate, values) => {
    onSubmit(values);
    const result = await validate();
    if (!Object.keys(result)?.length) {
      goNext();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors, setFieldValue, values, validateForm,
      }) => (
        <Form>
          <AutoSubmit onSubmit={onSubmit} />
          <Paper elevation={0}>
            <div className="form-content max-width">
              {header}
              <div className="form-item">
                <Typography>{i18n('POST_FORM_SELECT_CATEGORY')}</Typography>
                <Field
                  as={NewsCategorySelect}
                  name="newsCategory"
                  textFieldVariant="outlined"
                  placeholder={i18n('CATEGORY')}
                  size="small"
                  error={Boolean(errors.newsCategory)}
                  helperText={errors.newsCategory}
                  value={values.newsCategory}
                  onChange={(ev, value) => setFieldValue('newsCategory', value)}
                  disableClearable
                />
              </div>
              <div className="form-item">
                <Typography>{i18n('POST_FORM_SELECT_OFFFICES')}</Typography>
                <Typography sx={{ fontSize: '0.875rem', color: 'grey.600' }}>{i18n('POST_FORM_OFFICE_HELP')}</Typography>
                <Field
                  as={OfficeSelect}
                  name="offices"
                  textFieldVariant="outlined"
                  placeholder={i18n('OFFICES')}
                  size="small"
                  error={Boolean(errors.offices)}
                  helperText={errors.offices}
                  value={values.offices}
                  onChange={(ev, value) => setFieldValue('offices', value)}
                  disableCloseOnSelect
                  multiple
                  limitTags={0}
                  renderTags={(value) => (
                    <>
                      +
                      {value.length}
                    </>
                  )}
                />
              </div>
              <div className="form-item">
                <Typography>{i18n('POST_FORM_SELECT_AUDIENCE')}</Typography>
                <Typography sx={{ fontSize: '0.875rem', color: 'grey.600' }}>{i18n('POST_FORM_OFFICE_HELP')}</Typography>
                <Field
                  as={OfficeSelect}
                  name="audienceOffices"
                  textFieldVariant="outlined"
                  placeholder={i18n('OFFICES')}
                  size="small"
                  error={Boolean(errors.audienceOffices)}
                  helperText={errors.audienceOffices}
                  value={values.audienceOffices}
                  onChange={(ev, value) => setFieldValue('audienceOffices', value)}
                  disableCloseOnSelect
                  multiple
                  limitTags={0}
                  renderTags={(value) => (
                    <>
                      +
                      {value.length}
                    </>
                  )}
                />
              </div>

            </div>
          </Paper>
          <div className="bottom-bar" style={{ justifyContent: editionMode ? 'flex-end' : 'space-between' }}>
            {!editionMode && (<Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={() => goBack()}>{i18n('BACK')}</Button>)}
            <Button variant="light" sx={{ textTransform: 'none' }} onClick={() => wrappedGoNext(validateForm, values)}>{i18n('NEXT_STEP')}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

NewsForm.propTypes = {
  goBack: PropTypes.func,
  goNext: PropTypes.func,
  onSubmit: PropTypes.func,
  editing: PropTypes.object,
  header: PropTypes.node,
  editionMode: PropTypes.bool,
};

NewsForm.defaultProps = {
  goBack: () => {},
  goNext: () => {},
  onSubmit: () => {},
  editing: null,
  header: null,
  editionMode: false,
};

export default NewsForm;
