import RequestError from './requestError';
import { handleLogout, getSessionData } from './session';

const handleApiError = (error, payload) => {
  throw new RequestError(error, payload);
};

const authorizedFetch = async (url, options) => {
  const sessionData = getSessionData();

  const reqOptions = {
    credentials: 'include',
    ...options,
    headers: { ...(options?.headers || {}), authorization: `Bearer ${sessionData?.token}` },
  };

  const response = await fetch(url, reqOptions);
  if (!response.ok && response.status !== 401) {
    const result = await response.json();
    handleApiError(result, options?.body);
  } else if (!response.ok && response.status === 401) {
    // handle logout if token is expired
    handleLogout();
  }

  return response;
};

const regularFetch = async (url, options) => {
  const reqOptions = {
    credentials: 'include',
    ...options,
  };

  const response = await fetch(url, reqOptions);
  if (!response.ok) {
    const result = await response.json();
    handleApiError(result, options?.body);
  }

  return response;
};

const customFetch = (url, options, authorized = true) => {
  let fetchFunc = authorizedFetch;
  if (!authorized) {
    fetchFunc = regularFetch;
  }
  return fetchFunc(url, options);
};

export default customFetch;
