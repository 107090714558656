import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

/**
 * Listens to formik form state changes and calls to a submit handler
 */
const AutoSubmit = ({ onSubmit }) => {
  const formik = useFormikContext();

  const debouncedSubmit = useCallback(
    debounce((v) => (onSubmit ? onSubmit(v) : null), 1000),
    [onSubmit],
  );

  useEffect(() => { debouncedSubmit(formik.values); }, [debouncedSubmit, formik.values]);

  return null;
};

AutoSubmit.propTypes = {
  onSubmit: PropTypes.func,
};

AutoSubmit.defaultProps = {
  onSubmit: () => {},
};

export default AutoSubmit;
