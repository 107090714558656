import { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Paper from 'components/common/paper';
import { CONTENT_TYPES } from 'components/entities/post/params';
import i18n from 'utils/i18n';
import NewsForm from './news';
import EventsForm from './events';
import QclubForm from './qclub';
import CommonForm from './common';
import ContentForm from './content';
import FormHeader from './formHeader';
import SubmitDialog from './submitDialog';

const FormContainer = styled('div')(({ theme }) => ({
  height: '100%',
  '.form-content': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 2}px`,
    '.tag-container': {
      display: 'flex',
      gap: `${theme.basicGap * 2}px`,
      '.tag': {
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.basicGap}px`,
        padding: `${theme.basicGap}px ${theme.basicGap * 2}px`,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '8px',
        lineHeight: 1,
        width: 'fit-content',
        cursor: 'pointer',
      },
    },
    '.form-item': {
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.basicGap}px`,
    },
    '.MuiInputBase-root': {
      borderRadius: '8px',
    },
    '.MuiFormHelperText-root ': {
      margin: '0px',
    },
  },
  '.max-width': {
    '.MuiInputBase-root': {
      maxWidth: '12rem',
    },
  },
  '.bottom-bar': {
    paddingTop: `${theme.basicGap * 3}px`,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const CONTENT_QCLUB = 'q_clubs';

/**
 * Renders the full post form
 */
const PostForm = ({ onSubmit, editing }) => {
  const [step, setStep] = useState(editing ? 1 : 0);
  const [contentType, setContentType] = useState(editing?.stepOneData?.qClub ? CONTENT_QCLUB : editing?.type || null);
  const [stepOneData, setStepOneData] = useState(editing?.stepOneData || null);
  const [stepTwoData, setStepTwoData] = useState(editing?.stepTwoData || null);
  const [stepThreeData, setStepThreeData] = useState(editing?.stepThreeData || null);
  const [postConfirmOpen, setPostConfirmOpen] = useState(false);

  const handleStepCeroSubmit = (selectedContentType) => {
    setContentType(selectedContentType);
    setStep(1);
  };

  const handleStepOneSubmit = (values) => {
    setStepOneData(values);
  };

  const handleStepTwoSubmit = (values) => {
    setStepTwoData(values);
  };

  const handleStepThreeSubmit = (values) => {
    setStepThreeData(values);
  };

  const handleLastSubmit = ({ visibleFrom = undefined } = {}) => {
    setPostConfirmOpen(false);
    onSubmit({
      type: contentType,
      ...stepOneData,
      ...stepTwoData,
      content: stepThreeData,
      visibleFrom,
    });
  };

  return (
    <>
      <FormContainer>
        {/* Step 0 : Select content type */}
        {!step && (
        <Paper elevation={0}>
          <div className="form-content">
            <Typography color="secondary" className="medium" sx={{ fontSize: '1.125rem' }}>{i18n('POST_FORM_POSTIN')}</Typography>
            <Typography>{i18n('POST_FORM_SELECT_CONTENT')}</Typography>
            <div className="tag-container">
              <div className="tag" onClick={() => handleStepCeroSubmit(CONTENT_TYPES.NEWS)}>
                <FeedOutlinedIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                {i18n('NEWS')}
              </div>
              <div className="tag" onClick={() => handleStepCeroSubmit(CONTENT_TYPES.EVENT)}>
                <CalendarTodayOutlinedIcon sx={{ width: '1.2rem', height: '1.2rem' }} />
                {i18n('EVENTS')}
              </div>
              <div className="tag" onClick={() => handleStepCeroSubmit(CONTENT_QCLUB)}>
                <LocalLibraryOutlinedIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                {i18n('Q_CLUBS')}
              </div>
            </div>
          </div>
        </Paper>
        )}
        {/* Step 1: Fill data depending on content type */}
        { step === 1 && contentType === CONTENT_TYPES.NEWS && (
          <NewsForm
            editing={stepOneData}
            goBack={() => setStep(0)}
            goNext={() => setStep(2)}
            onSubmit={handleStepOneSubmit}
            header={<FormHeader step={step} type={contentType} />}
            editionMode={!!editing}
          />
        )}
        { step === 1 && contentType === CONTENT_TYPES.EVENT && (
          <EventsForm
            editing={stepOneData}
            goBack={() => setStep(0)}
            goNext={() => setStep(2)}
            onSubmit={handleStepOneSubmit}
            header={<FormHeader step={step} type={contentType} />}
            editionMode={!!editing}
          />
        )}
        { step === 1 && contentType === CONTENT_QCLUB && (
          <QclubForm
            editing={stepOneData}
            goBack={() => setStep(0)}
            goNext={() => setStep(2)}
            onSubmit={handleStepOneSubmit}
            header={<FormHeader step={step} type={contentType} />}
            editionMode={!!editing}
          />
        )}
        {/* Step 2: Fill common post data */}
        {step === 2 && (
          <CommonForm
            editing={stepTwoData}
            goBack={() => setStep(1)}
            goNext={() => setStep(3)}
            onSubmit={handleStepTwoSubmit}
            header={<FormHeader step={step} type={contentType} />}
          />
        )}
        {/* Step 3: Fill post content */}
        {step === 3 && (
          <ContentForm
            editing={stepThreeData}
            goBack={() => setStep(2)}
            goNext={() => setPostConfirmOpen(true)}
            onSubmit={handleStepThreeSubmit}
            header={<FormHeader step={step} type={contentType} />}
            editionMode={!!editing}
          />
        )}
      </FormContainer>
      {/* Last step: Submit dialog */}
      <SubmitDialog
        open={postConfirmOpen}
        onCancel={() => setPostConfirmOpen(false)}
        onSubmit={handleLastSubmit}
        editing={editing?.visibleFrom}
        editionMode={!!editing}
      />
    </>
  );
};

PostForm.propTypes = {
  onSubmit: PropTypes.func,
  editing: PropTypes.object,
};

PostForm.defaultProps = {
  onSubmit: () => {},
  editing: null,
};

export default PostForm;
