import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

const DataSelect = ({
  dataObject, dataPromise, itemLabelPath, itemValuePath, label, error, helperText, ctl, fullObject, textFieldVariant, placeholder, ...props
}) => {
  const [data, setData] = useState([]);

  const setDataSource = async () => {
    let dataSource;
    if (dataPromise) {
      dataSource = await dataPromise();
      dataSource = dataSource.data;
    } else if (dataObject) {
      dataSource = dataObject;
    }

    if (dataSource) {
      setData(dataSource?.map((item) => {
        const parsed = fullObject ? { ...item } : {};
        if (Object.prototype.toString.call(item) === '[object String]') {
          parsed[itemLabelPath] = item;
          parsed[itemValuePath] = item;
        } else {
          parsed[itemLabelPath] = item[itemLabelPath];
          parsed[itemValuePath] = item[itemValuePath];
          if (ctl) {
            parsed.ctlLabel = ctl(item[itemLabelPath]);
          }
        }
        return parsed;
      }));
    }
  };

  useEffect(() => {
    setDataSource();
  }, [dataObject]);

  return (
    <Fragment>
      {data && (
        <Autocomplete
          options={data}
          isOptionEqualToValue={(opt, value) => opt[itemValuePath] === value[itemValuePath]}
          getOptionLabel={(option) => option.ctlLabel || option[itemLabelPath]}
          renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} variant={textFieldVariant} placeholder={placeholder} />}
          {...props}
        />
      )}
    </Fragment>
  );
};

DataSelect.propTypes = {
  dataObject: PropTypes.any, // data object
  dataPromise: PropTypes.func, // The promise
  itemLabelPath: PropTypes.string.isRequired, // Property path for the option label
  itemValuePath: PropTypes.string.isRequired, // Property path for the option value
  label: PropTypes.string, // Select label
  error: PropTypes.bool, // error
  helperText: PropTypes.string, // error message
  ctl: PropTypes.func, // code to label function
  fullObject: PropTypes.bool, // if keep full object or just label and id
  textFieldVariant: PropTypes.string, // for textfield variant
  placeholder: PropTypes.string, // for placeholder
};

DataSelect.defaultProps = {
  dataObject: null,
  dataPromise: null,
  label: '',
  error: false,
  helperText: '',
  ctl: null,
  fullObject: false,
  textFieldVariant: 'standard',
  placeholder: null,
};

export default DataSelect;
