import DataSelect from 'components/common/dataSelect';
import i18n from 'utils/i18n';

const EventDateFilter = (props) => {
  const data = [{ name: i18n('UPCOMING_EVENTS'), value: 'upcoming' }, { name: i18n('PAST_EVENTS'), value: 'past' }];

  return (
    <DataSelect
      dataObject={data}
      itemLabelPath="name"
      itemValuePath="value"
      {...props}
    />
  );
};

export default EventDateFilter;
