import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useLocation, useSearchParams,
} from 'react-router-dom';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LaunchIcon from '@mui/icons-material/Launch';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Button from 'components/common/button';
import Paper from 'components/common/paper';
import Table from 'components/common/table';
import ResourceService from 'services/resourceService';
import i18n from 'utils/i18n';
import ResourceIcon from './icon';

const TopbarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap}px`,
}));

const ResourceNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 2}px`,
  alignItems: 'center',
}));

const TopButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 2}px`,
  paddingBottom: `${theme.basicGap * 2}px`,
  alignItems: 'center',
}));

const RenderResourceName = ({ row }) => (
  <ResourceNameContainer>
    <ResourceIcon type={row.type} mimeType={row.mimeType} />
    {' '}
    {row.name}
  </ResourceNameContainer>
);

RenderResourceName.propTypes = {
  row: PropTypes.object.isRequired,
};

const VisibilityIconOpt = (props) => (<VisibilityIcon sx={{ color: 'grey.500', width: '1.25rem' }} {...props} />);
const SaveAltIconOpt = (props) => (<SaveAltIcon sx={{ color: 'grey.500', width: '1.25rem' }} {...props} />);

const ResourcesPage = () => {
  const [directory, setDirectory] = useState(null);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchData = async () => {
    try {
      const path = encodeURIComponent(searchParams.get('path') || '');
      const direcoryData = await ResourceService.getAll({ path });
      setDirectory(direcoryData.data);
    } catch (error) {
      setDirectory(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const tableHeader = [
    { display: i18n('NAME'), property: 'name', customComponent: RenderResourceName },
  ];

  const navigateToPath = (path) => {
    setSearchParams({ path });
  };

  const navigateToSharepoint = (url) => window.open(url, '_blank');

  const handleRowClick = (row) => {
    if (row.type === 'directory') {
      navigateToPath(row.path);
    }
  };

  const handleViewClick = (row) => {
    if (row.type === 'directory') {
      navigateToPath(row.path);
    } else {
      navigateToSharepoint(row.sharepointUrl);
    }
  };

  const handleDownload = (row) => {
    if (row.type === 'directory') {
      navigateToPath(row.path);
    } else {
      navigateToSharepoint(row.downloadUrl);
    }
  };

  const handleBackClick = () => {
    navigateToPath(directory.parentPath);
  };

  const rowOptions = [
    {
      icon: SaveAltIconOpt,
      handleClick: handleDownload,
      isVisible: (row) => row.type === 'file',
    },
    {
      icon: VisibilityIconOpt,
      handleClick: handleViewClick,
    },
  ];

  const getParentsFromPath = (path) => `/${path}`.match(/\/([^/]*)/g)?.map((v) => v.replace('/', ''));

  const generateBreadCrumb = (path) => {
    const pathsList = getParentsFromPath(path);
    let itemFullPath = '';
    const breadcrumbItems = [];
    pathsList.forEach((itemPath, idx) => {
      itemFullPath = `${itemFullPath}/${itemPath}`;
      const newPath = `${itemFullPath}`;
      breadcrumbItems.push(<Button variant="breadcrumb-active" key={`res_bc_${idx}`} onClick={() => navigateToPath(newPath)}>{itemPath}</Button>);
    });
    return breadcrumbItems;
  };

  return (
    <Page>
      <TopBar>
        <TopbarContainer>
          <Button variant="breadcrumb-active" startIcon={<SettingsOutlinedIcon />} onClick={() => navigateToPath('')}>{i18n('BRAND_RESOURCES')}</Button>
          {
          directory && directory?.path !== '' && (generateBreadCrumb(directory.path))
      }
        </TopbarContainer>
      </TopBar>
      <div className="page-body">
        <TopButtonsContainer>
          { directory && directory?.path !== '' && (
            <Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={handleBackClick}>{i18n('BACK')}</Button>
          )}
          <Button variant="breadcrumb-active" sx={{ color: 'secondary.main' }} endIcon={<LaunchIcon />} onClick={() => navigateToSharepoint(directory.sharepointUrl)}>
            {i18n('OPEN_SP')}
          </Button>
        </TopButtonsContainer>
        <Paper
          elevation={0}
          sx={{
            '& .MuiTableCell-root': {
              fontSize: '1rem',
            },
          }}
        >
          { !directory && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color="secondary" />
            </div>
          )}
          { directory && (
            <Table
              rows={directory?.resources || []}
              header={tableHeader}
              handleClick={handleRowClick}
              headless
              rowOptions={rowOptions}
            />
          )}
        </Paper>
      </div>
    </Page>
  );
};

export default ResourcesPage;
