import { styled } from '@mui/material/styles';
import Button from 'components/common/button';
import PropTypes from 'prop-types';
import i18n from 'utils/i18n';

const BreadCrumbContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap}px`,
}));

const BreadCrumb = ({ breadCrumbData, selected, setSelected }) => {
  const breadCrumbs = breadCrumbData.map((breadCrumb, idx) => (
    <Button
      startIcon={breadCrumb.icon}
      variant={idx === selected ? 'breadcrumb-active' : 'breadcrumb'}
      key={`bc_${idx}`}
      onClick={() => {
        if (setSelected) {
          setSelected(idx);
        }
        document.querySelector(breadCrumb.scrollTo)?.scrollIntoView({ behavior: 'smooth' });
      }}
    >
      {i18n(breadCrumb.label)}
    </Button>
  ));

  return (
    <BreadCrumbContainer>
      {breadCrumbs}
    </BreadCrumbContainer>
  );
};

BreadCrumb.propTypes = {
  breadCrumbData: PropTypes.array.isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func,
};

BreadCrumb.defaultProps = {
  selected: -1,
  setSelected: null,
};

export default BreadCrumb;
