import DataSelect from 'components/common/dataSelect';
import AppService from 'services/appService';

const CategorysSelect = (props) => {
  const dataPromise = () => AppService.category.getAll();

  return (
    <DataSelect
      dataPromise={dataPromise}
      itemLabelPath="name"
      itemValuePath="id"
      {...props}
    />
  );
};

export default CategorysSelect;
