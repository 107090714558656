import Typography from '@mui/material/Typography';
import BasicTimeLine from 'components/common/timeline';
import Paper from 'components/common/paper';
import i18n from 'utils/i18n';

const TimeLine = () => {
  const data = [{
    label: '2006',
    description: i18n('ABOUT_TLINE_2006'),
  }, {
    label: '2012',
    description: i18n('ABOUT_TLINE_2012'),
  }, {
    label: '2014',
    description: i18n('ABOUT_TLINE_2014'),
  }, {
    label: '2018',
    description: i18n('ABOUT_TLINE_2018'),
  }, {
    label: '2020',
    description: i18n('ABOUT_TLINE_2020'),
  }, {
    label: '2021',
    description: i18n('ABOUT_TLINE_2021'),
  }, {
    label: '2022',
    description: i18n('ABOUT_TLINE_2022'),
  }, {
    label: '2023',
    description: i18n('ABOUT_TLINE_2023'),
  }];

  return (
    <Paper elevation={0} id="timeline">
      <Typography className="medium">{i18n('TIMELINE')}</Typography>
      <br />
      <BasicTimeLine data={data} />
    </Paper>
  );
};

export default TimeLine;
