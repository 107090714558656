import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import i18n from 'utils/i18n';

const FormHeader = ({ type, step }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Typography color="secondary" className="medium" sx={{ fontSize: '1.125rem' }}>{i18n(type.toUpperCase())}</Typography>
    <Typography color="secondary" className="medium">{i18n('POST_FORM_STEPS', { replacements: { step } })}</Typography>
  </div>
);

FormHeader.propTypes = {
  type: PropTypes.string,
  step: PropTypes.number,
};

FormHeader.defaultProps = {
  type: '',
  step: 0,
};

export default FormHeader;
