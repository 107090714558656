import Typography from '@mui/material/Typography';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { styled } from '@mui/material/styles';
import i18n from 'utils/i18n';
import Paper from 'components/common/paper';

const InfoGridContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: `${theme.basicGap * 3}px`,
  '.infogrid-col-left': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 3}px`,
    width: '280px',
    minWidth: '280px',
  },
  '.infogrid-col-right': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 3}px`,
    flex: 1,
    '.content-paper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '.content-col': {
        // maxWidth: '260px',
        // minWidth: '200px',
        flex: 1,
      },
      '.content-row': {
        display: 'flex',
        gap: `${theme.basicGap * 4}px`,
        justifyContent: 'space-evenly',
      },
    },
  },
}));

const InfoGrid = () => (
  <InfoGridContainer id="infogrid">
    <div className="infogrid-col-left">
      <Paper elevation={0} className="content-paper">
        <Typography className="medium">{i18n('WHO_WE_ARE')}</Typography>
        <br />
        <Typography dangerouslySetInnerHTML={{
          __html: i18n('WHO_WE_ARE_COPY'),
        }}
        />
      </Paper>
      <Paper elevation={0} className="content-paper" sx={{ flex: 1 }}>
        <Typography className="medium">{i18n('MISSION')}</Typography>
        <br />
        <Typography
          color="secondary"
          sx={{ fontSize: '1.37rem' }}
          dangerouslySetInnerHTML={{
            __html: i18n('ABOUT_MISSION_COPY'),
          }}
        />
      </Paper>
    </div>
    <div className="infogrid-col-right">
      <Paper elevation={0} className="content-paper">
        <Typography className="medium">{i18n('CORE_PILLARS')}</Typography>
        <br />
        <div className="content-row">
          <div className="content-col">
            <StarOutlineIcon color="secondary" />
            <Typography color="secondary" variant="h6">{i18n('EXPERIENCE')}</Typography>
            <Typography dangerouslySetInnerHTML={{
              __html: i18n('CORE_PILLARS_EXP_COPY'),
            }}
            />
          </div>
          <div className="content-col">
            <PeopleOutlineIcon color="secondary" />
            <Typography color="secondary" variant="h6">{i18n('TEAM')}</Typography>
            <Typography dangerouslySetInnerHTML={{
              __html: i18n('CORE_PILLARS_TEAM_COPY'),
            }}
            />
          </div>
          <div className="content-col">
            <EnergySavingsLeafOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="h6">{i18n('SUSTAINABILITY')}</Typography>
            <Typography dangerouslySetInnerHTML={{
              __html: i18n('CORE_PILLARS_STN_COPY'),
            }}
            />
          </div>
        </div>
      </Paper>
      <Paper elevation={0} className="content-paper">
        <Typography className="medium">{i18n('CORE_VALUES')}</Typography>
        <br />
        <div className="content-row">
          <div className="content-col">
            <PublicOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="h6">{i18n('COMMITMENT')}</Typography>
            <Typography dangerouslySetInnerHTML={{
              __html: i18n('CORE_VALUES_COMM_COPY'),
            }}
            />
          </div>
          <div className="content-col">
            <HandshakeOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="h6">{i18n('PARTNERSHIP')}</Typography>
            <Typography dangerouslySetInnerHTML={{
              __html: i18n('CORE_VALUES_PART_COPY'),
            }}
            />
          </div>
          <div className="content-col">
            <VerifiedOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="h6">{i18n('EXCELLENCE')}</Typography>
            <Typography dangerouslySetInnerHTML={{
              __html: i18n('CORE_VALUES_EXC_COPY'),
            }}
            />
          </div>
        </div>
      </Paper>
    </div>
  </InfoGridContainer>
);

export default InfoGrid;
