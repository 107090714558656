import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Paper from 'components/common/paper';

const HomePage = () => (
  <Page>
    <TopBar />
    <div className="page-body">
      <Paper elevation={0}>
        Test page
      </Paper>
    </div>
  </Page>
);

export default HomePage;
