import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const CustomLink = styled(Link)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`,
  p: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

export default CustomLink;
