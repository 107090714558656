import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import i18n from 'utils/i18n';
import Paper from 'components/common/paper';

const MapContainer = styled('div')(() => ({
  '.MuiPaper-root': {
    height: 'fit-content',
  },
  iframe: {
    width: '100%',
    aspectRatio: '2/1',
    border: 'none',
    margin: '0 auto',
  },
}));

const Map = () => (
  <MapContainer id="map">
    <Paper elevation={0}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div>
          <Typography className="medium">{i18n('QE_MAP')}</Typography>
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <br />
          <iframe src="https://datawrapper.dwcdn.net/yLVjZ/1/" title="Chart" />
        </div>
      </div>
    </Paper>
  </MapContainer>
);

export default Map;
