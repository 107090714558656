/* eslint max-classes-per-file:0 */
import config from 'config';
import GenericService from './genericService';

class InternalAppService extends GenericService {
  constructor() {
    super(config.API.APP);
  }

  create(values) {
    return this.genericReqMultipart(values, { method: 'POST' });
  }

  update(id, values) {
    return this.genericReqMultipart(values, { urlExtra: `/${id}`, method: 'PUT' });
  }
}

class AppService {
  constructor() {
    this.app = new InternalAppService();
    this.category = new GenericService(config.API.APP_CATEGORY);
  }
}

export default new AppService();
