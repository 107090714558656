import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Paper from 'components/common/paper';
import i18n from 'utils/i18n';

const ReportsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 3}px`,
  '.bottom': {
    display: 'flex',
    gap: `${theme.basicGap * 3}px`,
  },
  '.esg-container': {
    display: 'flex',
    gap: `${theme.basicGap * 4}px`,
  },
  '.esg': {
    display: 'flex',
    gap: `${theme.basicGap}px`,
    marginTop: `${theme.basicGap}px`,
    '.esg-item': {
      borderRadius: '8px',
      width: '125px',
      aspectRatio: '1/1.41',
      backgroundSize: 'cover',
      cursor: 'pointer',
      color: 'white',
      ':hover': {
        '& .esg-cover': {
          display: 'flex',
        },
      },
    },
    '.esg-cover': {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
      flexDirection: 'column',
      borderRadius: '8px',
      display: 'none',
      gap: `${theme.basicGap}px`,
    },
  },
  '.goals': {
    display: 'flex',
    gap: `${theme.basicGap}px`,
    marginTop: `${theme.basicGap}px`,
    flexWrap: 'wrap',
    img: {
      borderRadius: '8px',
      width: '75px',
    },
  },
}));

const EsgItem = ({ img, label, file }) => (
  <div className="esg">
    <div className="esg-item" style={{ backgroundImage: `url("/${img}")` }}>
      <a href={`/${file}`} target="_blank" style={{ color: 'inherit', textDecoration: 'none' }} rel="noreferrer">
        <div className="esg-cover">
          <SaveAltIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
          <Typography className="semi-bold">{label}</Typography>
        </div>
      </a>
    </div>
  </div>
);

EsgItem.propTypes = {
  img: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
};

const Reports = () => (
  <ReportsContainer id="reports">
    <Paper elevation={0}>
      <Typography className="medium">{i18n('ESG_INFO_TITLE')}</Typography>
      <br />
      <ul>
        <li dangerouslySetInnerHTML={{
          __html: i18n('ESG_INFO_1'),
        }}
        />
        <li dangerouslySetInnerHTML={{
          __html: i18n('ESG_INFO_2'),
        }}
        />
        <li dangerouslySetInnerHTML={{
          __html: i18n('ESG_INFO_3'),
        }}
        />
        <li dangerouslySetInnerHTML={{
          __html: i18n('ESG_INFO_4'),
        }}
        />
        <li dangerouslySetInnerHTML={{
          __html: i18n('ESG_INFO_5'),
        }}
        />
        <li dangerouslySetInnerHTML={{
          __html: i18n('ESG_INFO_6'),
        }}
        />
        <li dangerouslySetInnerHTML={{
          __html: i18n('ESG_INFO_7'),
        }}
        />
      </ul>
    </Paper>
    <div className="bottom">
      <Paper elevation={0}>
        <Typography className="medium">{i18n('ESG_REPORTS')}</Typography>
        <br />
        <div className="esg-container">
          <div>
            <Typography>{i18n('ENGLISH')}</Typography>
            <div className="esg">
              <EsgItem img="ESG-2021-EN.png" label="ESG 2021 (EN)" file="ESG Report Qualitas Energy 2021 (EN).pdf" />
              <EsgItem img="ESG-2020-EN.png" label="ESG 2020 (EN)" file="ESG Report Qualitas Energy 2020 (EN).pdf" />
            </div>
          </div>
          <div>
            <Typography>{i18n('SPANISH')}</Typography>
            <div className="esg">
              <EsgItem img="ESG-2021-ES.png" label="ESG 2021 (SP)" file="ESG Report Qualitas Energy 2021 (ES).pdf" />
              <EsgItem img="ESG-2020-ES.png" label="ESG 2020 (SP)" file="ESG Report Qualitas Energy 2020 (ES).pdf" />
            </div>
          </div>
        </div>
      </Paper>
      <Paper elevation={0} style={{ flex: 1 }}>
        <Typography className="medium">{i18n('GOALS_TITLE')}</Typography>
        <br />
        <div className="goals">
          <img src="/E-WEB-Goal-05.png" alt="ESG 2021" loading="lazy" />
          <img src="/E-WEB-Goal-07.png" alt="ESG 2020" loading="lazy" />
          <img src="/E-WEB-Goal-08.png" alt="ESG 2020" loading="lazy" />
          <img src="/E-WEB-Goal-09.png" alt="ESG 2020" loading="lazy" />
          <img src="/E-WEB-Goal-11.png" alt="ESG 2020" loading="lazy" />
          <img src="/E-WEB-Goal-12.png" alt="ESG 2020" loading="lazy" />
          <img src="/E-WEB-Goal-13.png" alt="ESG 2020" loading="lazy" />
          <img src="/E-WEB-Goal-15.png" alt="ESG 2020" loading="lazy" />
          <img src="/E-WEB-Goal-16.png" alt="ESG 2020" loading="lazy" />
        </div>
        <br />
        {i18n('MORE_INFO')}
        {' '}
        <a href="https://qualitasenergy.com/en/sustainability/" target="_blank" rel="noreferrer">{i18n('HERE').toLowerCase()}</a>
      </Paper>
    </div>

  </ReportsContainer>
);

export default Reports;
