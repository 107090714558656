import config from 'config';
import GenericService from './genericService';

class UserService extends GenericService {
  constructor() {
    super(config.API.USER);
  }

  adSync() {
    return this.genericReq({ urlExtra: '/sync', method: 'POST' });
  }

  getByEmails(emails) {
    const urlExtra = this.filtersToQs('/byemails', { emails });
    return this.genericReq({ urlExtra, method: 'GET' });
  }
}

export default new UserService();
