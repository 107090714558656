import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { red } from '@mui/material/colors';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from 'components/common/button';
import Dialog from 'components/common/dialog';
import EditButton from 'components/common/editButton';
import i18n from 'utils/i18n';

const DialogContainer = styled('div')(({ theme }) => ({
  '.buttons': {
    display: 'flex',
    gap: `${theme.basicGap * 2}px`,
    paddingTop: `${theme.basicGap * 4}px`,
  },
  '.form': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 4}px`,
    paddingTop: `${theme.basicGap * 2}px`,
  },
}));

const CategoryDialog = ({
  category, onClose, onSubmit, onDelete, ...props
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const initialValues = {
    name: category?.name || '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(i18n('MANDATORY')),
  });

  const handleClose = () => {
    onClose();
    setTimeout(() => setConfirmOpen(false), 80);
  };

  const confirmButtons = [{
    label: 'CONTINUE',
    componentProps: {
      variant: 'light',
      onClick: () => setConfirmOpen(false),
    },
  },
  {
    label: 'LEAVE_WITHOUT_SAVING',
    componentProps: {
      variant: 'grey',
      onClick: handleClose,
    },
  },
  ];

  const wrappedDelete = () => {
    onDelete();
    setTimeout(() => setConfirmDeleteOpen(false), 80);
  };

  const confirmDeleteButtons = [{
    label: 'DELETE',
    componentProps: {
      variant: 'red',
      onClick: wrappedDelete,
    },
  },
  {
    label: 'CANCEL',
    componentProps: {
      variant: 'grey',
      onClick: () => setConfirmDeleteOpen(false),
    },
  },
  ];

  return (
    <>
      <Dialog
        {...props}
        title={category ? i18n('EDIT_APP_CATEGORY') : i18n('ADD_APP_CATEGORY')}
        sx={{
          '.MuiDialog-paper': {
            minWidth: '450px',
            maxWidth: '45%',
          },
          visibility: (confirmOpen || confirmDeleteOpen) ? 'hidden' : 'visible',
        }}
      >
        <DialogContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ errors }) => (
              <Form>
                <div className="form">
                  <Field
                    as={TextField}
                    name="name"
                    variant="standard"
                    label={i18n('NAME')}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    fullWidth
                  />
                </div>
                <div className="buttons">
                  <Button variant="light" type="submit">{i18n('SAVE')}</Button>
                  <Button variant="grey" onClick={() => setConfirmOpen(true)}>{i18n('CANCEL')}</Button>
                  {category && (
                    <EditButton icon={DeleteOutlineIcon} bgcolor={red[700]} onClick={() => setConfirmDeleteOpen(true)} />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </DialogContainer>
      </Dialog>
      <Dialog
        title={i18n('LEAVE_FORM_TITLE')}
        buttons={confirmButtons}
        open={confirmOpen}
      >
        {i18n('LEAVE_FORM')}
      </Dialog>
      <Dialog
        title={i18n('DELETE_FORM_TITLE')}
        buttons={confirmDeleteButtons}
        open={confirmDeleteOpen}
      >
        {i18n('DELETE_FORM_COPY')}
      </Dialog>
    </>
  );
};

CategoryDialog.propTypes = {
  category: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

CategoryDialog.defaultProps = {
  category: null,
  onClose: () => {},
  onSubmit: () => {},
  onDelete: () => {},
};

export default CategoryDialog;
