import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { red } from '@mui/material/colors';
import PostService from 'services/postService';
import { useAlert } from 'components/common/alert';
import Dialog from 'components/common/dialog';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Button from 'components/common/button';
import EditButton from 'components/common/editButton';
import i18n from 'utils/i18n';
import routes from 'routes.json';
import PostForm from './form';
import { postFormToApi, postApiToForm } from './adapters';
import { getBackUrl } from './helpers';

const PostEdit = () => {
  const pathParams = useParams();
  const postId = Number.parseInt(pathParams.id, 10);
  const [post, setPost] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const showAlert = useAlert();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const postData = await PostService.getOne(postId);
      const adaptedValues = postApiToForm(postData.data);
      setPost({
        raw: postData.data,
        adapted: adaptedValues,
      });
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      setIsSaving(true);
      const adaptedValues = postFormToApi(values);
      const created = await PostService.update(postId, adaptedValues);

      if (values?.image instanceof File) {
        try {
          await PostService.addImage(postId, values?.image);
        } catch (error) {
          showAlert(error.message, 'error');
        }
      }

      setIsSaving(false);
      navigate(`/${routes.POST.replace(':id', created?.data?.id)}`);
      showAlert(i18n('UPDATED_SUCCESS', { replacements: { item: i18n('POST') } }));
    } catch (error) {
      setIsSaving(false);
      showAlert(error.message, 'error');
    }
  };

  const handleDelete = async () => {
    try {
      await PostService.delete(postId);
      navigate(getBackUrl(post.raw));
      showAlert(i18n('DELETED_SUCCESS', { replacements: { item: i18n('POST') } }));
    } catch (error) {
      showAlert(error.message, 'error');
    }
  };

  const handleClose = () => {
    navigate(`/${routes.NEWS}`);
  };

  const confirmButtons = [{
    label: 'CONTINUE',
    componentProps: {
      variant: 'light',
      onClick: () => setIsLeaving(false),
    },
  },
  {
    label: 'LEAVE_WITHOUT_SAVING',
    componentProps: {
      variant: 'grey',
      onClick: handleClose,
    },
  },
  ];

  const confirmDeleteButtons = [{
    label: 'DELETE',
    componentProps: {
      variant: 'red',
      onClick: handleDelete,
    },
  },
  {
    label: 'CANCEL',
    componentProps: {
      variant: 'grey',
      onClick: () => setConfirmDeleteOpen(false),
    },
  },
  ];

  return (
    <Page>
      <TopBar>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
            <Button variant="breadcrumb-active" startIcon={<EditOutlinedIcon />}>{i18n('EDIT_POST')}</Button>
            <EditButton icon={DeleteOutlineIcon} bgcolor={red[700]} onClick={() => setConfirmDeleteOpen(true)} />
          </div>
          <Button variant="grey" onClick={() => setIsLeaving(true)}>{i18n('CANCEL')}</Button>
        </div>
      </TopBar>
      <div className="page-body">
        {post && (<PostForm onSubmit={handleSubmit} editing={post.adapted} />)}
      </div>
      <Dialog open={isSaving}>
        <div style={{
          alignItems: 'center', width: '200px', display: 'flex', flexDirection: 'column', gap: '32px',
        }}
        >
          <div>
            <Typography color="primary">{i18n('SAVING_DATA')}</Typography>
          </div>
          <CircularProgress />
        </div>
      </Dialog>
      <Dialog
        title={i18n('LEAVE_FORM_TITLE')}
        buttons={confirmButtons}
        open={isLeaving}
      >
        {i18n('LEAVE_FORM')}
      </Dialog>
      <Dialog
        title={i18n('DELETE_FORM_TITLE')}
        buttons={confirmDeleteButtons}
        open={confirmDeleteOpen}
      >
        {i18n('DELETE_FORM_COPY')}
      </Dialog>
    </Page>
  );
};

export default PostEdit;
