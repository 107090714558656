const Q_CLUBS = {
  KNOWLEDGE: 'qknowledge',
  COMMUNITY: 'qcommunity',
  DIVERSITY: 'qdiversity',
};

const ALL_QCLUBS = 'any';
const NO_QCLUB = 'none';

const CONTENT_TYPES = {
  NEWS: 'news',
  EVENT: 'event',
  // OTHER: 'other',
};

const Q_KNOWLEDGE_CATEGORIES = {
  NEWS: 'news',
  TALK: 'qtalk',
  TOWNHALL: 'townhall',
  TRAINING: 'qtraining',
  EDUCATION: 'education',
  REPORT: 'report',
  EXTEVENT: 'extevent',
};

const getClubColor = (qClub) => {
  let clubColor;
  switch (qClub) {
    case Q_CLUBS.KNOWLEDGE:
      clubColor = '#7BBF9C';
      break;
    case Q_CLUBS.COMMUNITY:
      clubColor = '#FF6660';
      break;
    case Q_CLUBS.DIVERSITY:
      clubColor = '#FFBC57';
      break;
    default:
      break;
  }
  return clubColor;
};

const SPECIAL_EVENTS_COLOR = '#1E1E1E';

export {
  Q_CLUBS,
  CONTENT_TYPES,
  Q_KNOWLEDGE_CATEGORIES,
  SPECIAL_EVENTS_COLOR,
  getClubColor,
  ALL_QCLUBS,
  NO_QCLUB,
};
