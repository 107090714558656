import PropTypes from 'prop-types';

const iconMap = {
  'image/*': 'icon-image',
  'video/*': 'icon-video',
  'application/pdf': 'icon-pdf',
  spreadsheetml: 'icon-xls',
  wordprocessingml: 'icon-doc',
  presentationml: 'icon-pp',
};

const ResourceIcon = ({ type, mimeType }) => {
  let image;

  if (type === 'directory') {
    image = 'icon-folder';
  } else if (mimeType) {
    const iconMatch = Object.keys(iconMap).find((regex) => mimeType.match(regex));

    if (iconMatch) {
      image = iconMap[iconMatch];
    }
  }

  if (!image) {
    image = 'icon-other';
  }

  const imagePath = `/${image}.svg`;
  return (
    <img src={imagePath} alt="icon" loading="lazy" />
  );
};

ResourceIcon.propTypes = {
  type: PropTypes.string.isRequired,
  mimeType: PropTypes.string,
};

ResourceIcon.defaultProps = {
  mimeType: null,
};

export default ResourceIcon;
