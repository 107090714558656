import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from 'components/common/button';
import Logo from 'components/common/logo';
import i18n from 'utils/i18n';
import { useSearchParams } from 'react-router-dom';
import { initAzureLogin, doAzureLogin } from 'utils/session';
import { useEffect } from 'react';

const LoginContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.secondary.main,
}));

const LoginBody = styled('div')(() => ({
  display: 'flex',
  width: 'fit-content',
  height: 'fit-content',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
}));

const LoginPage = () => {
  const handleLoginClick = () => {
    initAzureLogin();
  };

  return (
    <LoginContainer>
      <LoginBody>
        <Logo />
        <Button variant="light" color="secondary" sx={{ fontWeight: 500, textTransform: 'none' }} onClick={() => handleLoginClick()}>
          {i18n('SIGN_IN')}
        </Button>
        <div>
          <Typography variant="caption" paragraph>
            {i18n('SIGN_IN_COPY')}
            <br />
            {i18n('SIGN_IN_COPY_2')}
          </Typography>
        </div>
        <div>
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {i18n('COPYRIGHT')}
          </Typography>
        </div>
      </LoginBody>
    </LoginContainer>
  );
};

const AzureLogin = () => {
  const [searchParams] = useSearchParams();
  const msAuthData = searchParams.toString();

  const doLogin = async () => {
    await doAzureLogin(msAuthData);
  };

  useEffect(() => { doLogin(); }, []);

  return (
    <LoginContainer>
      <CircularProgress color="secondary" />
    </LoginContainer>
  );
};

export default LoginPage;
export { LoginPage, AzureLogin };
