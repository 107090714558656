import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { red } from '@mui/material/colors';
import Button from 'components/common/button';
import EditButton from 'components/common/editButton';
import Dialog from 'components/common/dialog';
import CategorysSelect from 'components/entities/app/categorySelect';
import CountrySelect from 'components/entities/country/select';
import ImageInput from 'components/common/imageInput';
import i18n from 'utils/i18n';
import isValidFileType from 'utils/validImageType';

const DialogContainer = styled('div')(({ theme }) => ({
  '.buttons': {
    display: 'flex',
    gap: `${theme.basicGap * 2}px`,
    paddingTop: `${theme.basicGap * 4}px`,
  },
  '.form': {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 4}px`,
    paddingTop: `${theme.basicGap * 2}px`,
  },
}));

const AppDialog = ({
  defaultCategory, app, onClose, onSubmit, onDelete, isLoading, ...props
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const initialValues = {
    image: app?.image || null,
    name: app?.name || '',
    description: app?.description || '',
    url: app?.url || '',
    category: app?.appCategory || defaultCategory || null,
    countries: app?.countries || [],
  };

  const validationSchema = Yup.object().shape({
    image: Yup
      .mixed()
      .test(
        'is-valid-type',
        i18n('INVALID_IMAGE'),
        (value) => typeof value === 'string' || isValidFileType(value && value.name.toLowerCase(), 'image'),
      ),
    name: Yup.string().required(i18n('MANDATORY')),
    description: Yup.string().required(i18n('MANDATORY')),
    url: Yup.string().url().required(i18n('MANDATORY')),
    category: Yup.object().required(i18n('MANDATORY')),
    countries: Yup.array().min(1).required(i18n('MANDATORY')),
  });

  const handleClose = () => {
    onClose();
    setTimeout(() => setConfirmOpen(false), 150);
  };

  const wrappedDelete = () => {
    onDelete();
    setTimeout(() => setConfirmDeleteOpen(false), 80);
  };

  const confirmButtons = [{
    label: 'CONTINUE',
    componentProps: {
      variant: 'light',
      onClick: () => setConfirmOpen(false),
    },
  },
  {
    label: 'LEAVE_WITHOUT_SAVING',
    componentProps: {
      variant: 'grey',
      onClick: handleClose,
    },
  },
  ];

  const confirmDeleteButtons = [{
    label: 'DELETE',
    componentProps: {
      variant: 'red',
      onClick: wrappedDelete,
    },
  },
  {
    label: 'CANCEL',
    componentProps: {
      variant: 'grey',
      onClick: () => setConfirmDeleteOpen(false),
    },
  },
  ];

  return (
    <>
      <Dialog
        {...props}
        title={app ? i18n('EDIT_APP') : i18n('ADD_APP')}
        sx={{
          '.MuiDialog-paper': {
            minWidth: '450px',
            maxWidth: '45%',
          },
          visibility: (confirmOpen || confirmDeleteOpen) ? 'hidden' : 'visible',
        }}
      >
        {isLoading && (
        <div style={{ padding: '8px', textAlign: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
        )}
        <DialogContainer style={{ display: isLoading ? 'none' : 'block' }}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, setFieldValue, values }) => (
              <Form>
                <div className="form">
                  <Field
                    as={ImageInput}
                    name="image"
                    variant="standard"
                    label={i18n('IMAGE')}
                    error={Boolean(errors.image)}
                    helperText={errors.image}
                    value={values.image}
                    onChange={(value) => setFieldValue('image', value)}
                  />
                  <Field
                    as={TextField}
                    name="name"
                    variant="standard"
                    label={i18n('NAME')}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    fullWidth
                  />
                  <Field
                    as={TextField}
                    name="description"
                    variant="standard"
                    label={i18n('DESCRIPTION')}
                    error={Boolean(errors.description)}
                    helperText={errors.description}
                    fullWidth
                  />
                  <Field
                    as={TextField}
                    name="url"
                    variant="standard"
                    label="Url"
                    error={Boolean(errors.url)}
                    helperText={errors.url}
                    fullWidth
                  />
                  <Field
                    as={CategorysSelect}
                    name="category"
                    textFieldVariant="outlined"
                    label={i18n('CATEGORY')}
                    error={Boolean(errors.category)}
                    helperText={errors.category}
                    value={values.category}
                    onChange={(ev, value) => setFieldValue('category', value)}
                    fullWidth
                  />
                  <Field
                    as={CountrySelect}
                    name="countries"
                    textFieldVariant="outlined"
                    label={i18n('COUNTRIES')}
                    error={Boolean(errors.countries)}
                    helperText={errors.countries}
                    value={values.countries}
                    onChange={(ev, value) => setFieldValue('countries', value)}
                    fullWidth
                    multiple
                    disableCloseOnSelect
                  />
                </div>
                <div className="buttons">
                  <Button variant="light" type="submit">{i18n('SAVE')}</Button>
                  <Button variant="grey" onClick={() => setConfirmOpen(true)}>{i18n('CANCEL')}</Button>
                  { app && (
                    <EditButton icon={DeleteOutlineIcon} bgcolor={red[700]} onClick={() => setConfirmDeleteOpen(true)} />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </DialogContainer>
      </Dialog>
      <Dialog
        title={i18n('LEAVE_FORM_TITLE')}
        buttons={confirmButtons}
        open={confirmOpen}
      >
        {i18n('LEAVE_FORM')}
      </Dialog>
      <Dialog
        title={i18n('DELETE_FORM_TITLE')}
        buttons={confirmDeleteButtons}
        open={confirmDeleteOpen}
      >
        {i18n('DELETE_FORM_COPY')}
      </Dialog>
    </>
  );
};

AppDialog.propTypes = {
  defaultCategory: PropTypes.object,
  app: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  onDelete: PropTypes.func,
};

AppDialog.defaultProps = {
  defaultCategory: null,
  app: null,
  onClose: () => {},
  onSubmit: () => {},
  isLoading: false,
  onDelete: () => {},
};

export default AppDialog;
