import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Dialog from 'components/common/dialog';
import { styled } from '@mui/material/styles';
import UserView from 'components/entities/user/view';
import Button from 'components/common/button';
import StateSelect from 'components/entities/user/stateSelect';
import RoleSelect from 'components/entities/user/roleSelect';
import UserService from 'services/userService';
import i18n from 'utils/i18n';

const DialogContainer = styled('div')(({ theme }) => ({
  '.buttons': {
    display: 'flex',
    gap: `${theme.basicGap * 2}px`,
    paddingTop: `${theme.basicGap * 4}px`,
  },
  '.content': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.basicGap * 2}px`,
  },
  '.form': {
    display: 'flex',
    gap: `${theme.basicGap * 2}px`,
  },
}));

const UserDialog = ({
  id, onSave, onClose, ...props
}) => {
  const [user, setUser] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const fetchData = async () => {
    const userData = await UserService.getOne(id);
    setUser(userData.data);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const validationSchema = Yup.object().shape({
    allowed: Yup.string().required(i18n('MANDATORY')),
    role: Yup.string().required(i18n('MANDATORY')),
  });

  const handleSubmit = (values) => {
    onSave(values);
  };

  const handleClose = () => {
    onClose();
  };

  const confirmButtons = [{
    label: 'CONTINUE',
    componentProps: {
      variant: 'light',
      onClick: () => setConfirmOpen(false),
    },
  },
  {
    label: 'LEAVE_WITHOUT_SAVING',
    componentProps: {
      variant: 'grey',
      onClick: handleClose,
    },
  },
  ];

  return (
    <>
      <Dialog
        {...props}
        sx={{
          visibility: confirmOpen ? 'hidden' : 'visible',
          '.MuiDialog-paper': {
            maxWidth: '90%',
          },
        }}
      >
        <DialogContainer>
          { user && (
          <Formik
            initialValues={{
              allowed: user.allowed || null,
              role: user.role || null,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors }) => (
              <Form>
                <div className="content">
                  {id && (
                  <UserView data={user} />
                  )}
                  <div className="form">
                    <Field
                      as={StateSelect}
                      name="allowed"
                      label={i18n('STATE')}
                      error={Boolean(errors.allowed)}
                      helperText={errors.allowed}
                    />
                    <Field
                      as={RoleSelect}
                      name="role"
                      label={i18n('ROLE')}
                      error={Boolean(errors.role)}
                      helperText={errors.role}
                    />
                  </div>
                </div>
                <div className="buttons">
                  <Button variant="light" type="submit">{i18n('SAVE')}</Button>
                  <Button variant="grey" onClick={() => setConfirmOpen(true)}>{i18n('CANCEL')}</Button>
                </div>
              </Form>
            )}
          </Formik>
          )}
        </DialogContainer>
      </Dialog>
      <Dialog
        title={i18n('LEAVE_FORM_TITLE')}
        buttons={confirmButtons}
        open={confirmOpen}
      >
        {i18n('LEAVE_FORM')}
      </Dialog>
    </>
  );
};

UserDialog.propTypes = {
  id: PropTypes.number.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

UserDialog.defaultProps = {
  onClose: () => {},
  onSave: () => {},
};

export default UserDialog;
