import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PageContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  flex: 1,
  padding: `${theme.basicGap * 3}px`,
  paddingLeft: `${theme.basicGap * 2}px`,
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 4}px`,
  '.page-body': {
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
}));

const Page = ({ children }) => (
  <PageContainer>
    {children}
  </PageContainer>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
