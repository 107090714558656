import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Typography from '@mui/material/Typography';
import {
  Q_CLUBS, Q_KNOWLEDGE_CATEGORIES, getClubColor, SPECIAL_EVENTS_COLOR,
} from 'components/entities/post/params';
import Paper from 'components/common/paper';
import i18n from 'utils/i18n';

const MenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 3}px`,
  padding: '1px',
}));

const StyledPaper = styled(Paper)(({ bordercolor, theme }) => ({
  flex: 1,
  display: 'flex',
  gap: `${theme.basicGap}px`,
  flexDirection: 'column',
  height: '50vh',
  maxHeight: '392px',
  justifyContent: 'center',
  cursor: 'pointer',
  div: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  ':hover': {
    outline: `1px solid ${bordercolor || getClubColor(Q_CLUBS.KNOWLEDGE)}`,
  },
}));

const MenuItem = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(data?.category);
  };

  return (
    <StyledPaper bordercolor={data?.borderColor} elevation={0} onClick={handleClick}>
      <div>{data.icon}</div>
      <div><Typography sx={{ fontSize: '1.5rem' }}>{i18n(data.label)}</Typography></div>
      <div><Typography sx={{ fontSize: '0.875rem' }}>{i18n(data.description)}</Typography></div>
    </StyledPaper>
  );
};

MenuItem.propTypes = {
  data: PropTypes.object.isRequired,
};

const QknowledgeMenu = ({ viewType }) => {
  const internalResourcesItems = [
    {
      icon: <ChatBubbleOutlineOutlinedIcon sx={{ width: '1.375rem', height: '1.375rem' }} />,
      label: 'Q_TALKS',
      description: 'QKNOW_MENU_TALK_COPY',
      category: Q_KNOWLEDGE_CATEGORIES.TALK,
      borderColor: SPECIAL_EVENTS_COLOR,
    },
    {
      icon: <ApartmentOutlinedIcon sx={{ width: '1.375rem', height: '1.375rem' }} />,
      label: 'TOWNHALL',
      description: 'QKNOW_MENU_TOWNHALL_COPY',
      category: Q_KNOWLEDGE_CATEGORIES.TOWNHALL,
      borderColor: SPECIAL_EVENTS_COLOR,
    },
    {
      icon: <SchoolOutlinedIcon sx={{ width: '1.375rem', height: '1.375rem' }} />,
      label: 'Q_TRAINING',
      description: 'QKNOW_MENU_TRAINING_COPY',
      category: Q_KNOWLEDGE_CATEGORIES.TRAINING,
    },
  ];

  const externalResourcesItems = [
    {
      icon: <SchoolOutlinedIcon sx={{ width: '1.375rem', height: '1.375rem' }} />,
      label: 'EDUCATION_N_FORMATION',
      description: 'QKNOW_MENU_EDUCATION_COPY',
      category: Q_KNOWLEDGE_CATEGORIES.EDUCATION,
    },
    {
      icon: <AutoStoriesOutlinedIcon sx={{ width: '1.375rem', height: '1.375rem' }} />,
      label: 'REPORTS',
      description: 'QKNOW_MENU_REPORTS_COPY',
      category: Q_KNOWLEDGE_CATEGORIES.REPORT,
    },
    {
      icon: <CalendarMonthOutlinedIcon sx={{ width: '1.375rem', height: '1.375rem' }} />,
      label: 'EXTERNAL_EVENTS',
      description: 'QKNOW_MENU_EXTEVENTS_COPY',
      category: Q_KNOWLEDGE_CATEGORIES.EXTEVENT,
    },
  ];

  return (
    <MenuContainer>
      { viewType === 0 && internalResourcesItems?.map((item, idx) => (<MenuItem data={item} key={`qknow_menu_${idx}`} />))}
      { viewType === 1 && externalResourcesItems?.map((item, idx) => (<MenuItem data={item} key={`qknow_menu_${idx}`} />))}
    </MenuContainer>
  );
};

QknowledgeMenu.propTypes = {
  viewType: PropTypes.number.isRequired,
};

export default QknowledgeMenu;
