import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from 'utils/i18n';

const SyncDialog = (props) => (
  <Dialog {...props}>
    <DialogContent>
      {i18n('SYNC_USERS_DIALOG_COPY')}
      <div style={{ textAlign: 'center', padding: '32px' }}>
        <CircularProgress color="secondary" />
      </div>
    </DialogContent>
  </Dialog>
);

export default SyncDialog;
