import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { styled } from '@mui/material/styles';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import Button from 'components/common/button';
import {
  Q_CLUBS, CONTENT_TYPES, getClubColor, ALL_QCLUBS,
} from 'components/entities/post/params';
import i18n from 'utils/i18n';
import routes from 'routes.json';

const BreadcrumbContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap}px`,
}));

// Generate breadcrumb
const QclubBreadcrumb = ({ type, selectedQclub }) => {
  const navigate = useNavigate();

  const qClubButtons = [{
    qClub: null,
    icon: <LocalLibraryOutlinedIcon />,
    label: 'Q_CLUBS',
  },
  {
    qClub: Q_CLUBS.KNOWLEDGE,
    label: 'QKNOWLEDGE',
  },
  {
    qClub: Q_CLUBS.COMMUNITY,
    label: 'QCOMMUNITY',
  },
  {
    qClub: Q_CLUBS.DIVERSITY,
    label: 'QDIVERSITY',
  },
  ];

  const generateQclubButtons = () => qClubButtons.map((buttonData, idx) => (
    <Button
      variant={selectedQclub === (buttonData?.qClub || ALL_QCLUBS) ? 'breadcrumb-active' : 'breadcrumb'}
      startIcon={buttonData?.icon || null}
      key={`breadcrum_qclub_${idx}`}
      onClick={() => navigate(`/${routes.QCLUBS.replace(':qClub?', buttonData?.qClub || '').replace('/:subQclub?', '')}`)}
      sx={{
        backgroundColor: selectedQclub === (buttonData?.qClub || ALL_QCLUBS) ? getClubColor(selectedQclub) : 'inherit',
        color: selectedQclub === (buttonData?.qClub) ? '#fff' : 'inherit',
        ':hover': {
          backgroundColor: getClubColor(buttonData?.qClub),
          color: buttonData?.qClub ? '#fff' : 'inherit',
        },
      }}
    >
      {i18n(buttonData.label)}
    </Button>
  ));

  return (
    <BreadcrumbContainer>
      {
        selectedQclub && (
          generateQclubButtons()
        )
      }
      {
        !selectedQclub && type === CONTENT_TYPES.NEWS && (
          <Button variant="breadcrumb-active" startIcon={<FeedOutlinedIcon />}>{i18n('QE_NEWS')}</Button>
        )
      }
      {
        !selectedQclub && type === CONTENT_TYPES.EVENT && (
          <Button variant="breadcrumb-active" startIcon={<CalendarTodayOutlinedIcon />}>{i18n('QE_EVENTS')}</Button>
        )
      }
    </BreadcrumbContainer>
  );
};

QclubBreadcrumb.propTypes = {
  type: PropTypes.string,
  selectedQclub: PropTypes.string,
};

QclubBreadcrumb.defaultProps = {
  type: null,
  selectedQclub: null,
};

export default QclubBreadcrumb;
