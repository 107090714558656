// Base URL depends on the environment
let baseUrl = process.env?.REACT_APP_API_URL || `${window.location.protocol}//${window.location.hostname}`;
baseUrl = `${baseUrl}/api`;

export default {
  APP_VERSION: 1.0,
  API: {
    BASE_URL: baseUrl,
    AUTH: {
      TOKEN: 'auth/token',
      URI: 'auth/uri',
    },
    USER: 'user',
    APP: 'app',
    CONFIG: 'config',
    CONTENT: 'content',
    RESOURCE: 'resource',
    APP_CATEGORY: 'app-category',
    FILE: 'file',
    POST: 'post',
  },
};
