import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import { styled } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor-custom/ckeditor';
import Paper from 'components/common/paper';
import Button from 'components/common/button';
import i18n from 'utils/i18n';
import { getClubColor, Q_CLUBS } from 'components/entities/post/params';
import config from 'config';

const InputContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.ck-editor': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '.ck-editor__main': {
    flex: 1,
  },
  '.ck-editor__editable_inline': {
    minHeight: '100%',
  },
  '--ck-border-radius': '8px',
  img: {
    borderRadius: '8px',
  },
}));

const editorConfig = {
  fontColor: {
    colors: [
      {
        color: '#000037',
        label: 'Default',
      },
      {
        color: '#0057B8',
        label: 'Light',
      },
      {
        color: getClubColor(Q_CLUBS.KNOWLEDGE),
        label: i18n('QKNOWLEDGE'),
      },
      {
        color: getClubColor(Q_CLUBS.COMMUNITY),
        label: i18n('QCOMMUNITY'),
      },
      {
        color: getClubColor(Q_CLUBS.DIVERSITY),
        label: i18n('QDIVERSITY'),
      },
    ],
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'post-content-regular' },
      {
        model: 'title1',
        view: {
          name: 'p',
          classes: 'post-content-title',
        },
        title: 'Title',
      },
      {
        model: 'title2',
        view: {
          name: 'p',
          classes: 'post-content-subtitle',
        },
        title: 'Subtitle',
      },
    ],
  },
  ImageUploadProgress: {
    isEnabled: true,
  },
  simpleUpload: {
    uploadUrl: `${config.API.BASE_URL}/file/image?origin=${config.API.BASE_URL}`,
    withCredentials: true,
  },
};

/**
 * Renders the form for  post content
 */
const ContentForm = ({
  goBack, goNext, editing, onSubmit, header, editionMode,
}) => {
  const debouncedSubmit = useCallback(
    debounce((v) => onSubmit(v), 1000),
    [onSubmit],
  );

  return (
    <InputContainer>
      <Paper elevation={0} sx={{ flex: 1 }}>
        <div className="form-content" style={{ height: ' 100%' }}>
          {header}
          <Typography>{i18n('POST_FORM_ADD_CONTENT')}</Typography>
          <CKEditor
            editor={Editor}
            config={editorConfig}
            data={editing}
            onChange={(event, editor) => {
              const data = editor.getData();
              debouncedSubmit(data);
            }}
          />
        </div>
      </Paper>
      <div className="bottom-bar">
        <Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={() => goBack()}>{i18n('BACK')}</Button>
        <Button variant="light" sx={{ textTransform: 'none' }} onClick={() => goNext()}>
          {editionMode ? i18n('SAVE') : i18n('SCHEDULE_OR_POST')}
        </Button>
      </div>
    </InputContainer>
  );
};

ContentForm.propTypes = {
  goBack: PropTypes.func,
  goNext: PropTypes.func,
  onSubmit: PropTypes.func,
  editing: PropTypes.string,
  header: PropTypes.node,
  editionMode: PropTypes.bool,
};

ContentForm.defaultProps = {
  goBack: () => {},
  goNext: () => {},
  onSubmit: () => {},
  editing: '',
  header: null,
  editionMode: false,
};

export default ContentForm;
