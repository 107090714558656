/* eslint no-prototype-builtins: 0 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import PostService from 'services/postService';
import Button from 'components/common/button';
import i18n from 'utils/i18n';
import PostCard from './card';

const PostContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 3}px`,
  flexWrap: 'wrap',
}));

// debounced fetch, declared outside for avoiding function redeclare at every render
const debouncedFetchData = debounce((fetch) => {
  fetch();
}, 300);

/**
 * Renders a filtered and paginated post list
 */
const PostList = ({ type, filters }) => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreItems, setNoMoreItems] = useState(false);
  const pageSize = 12;

  const parseFilters = () => {
    const parsedFilters = {};
    if (filters.category) {
      parsedFilters.newsCategoryId = filters.category.id;
    }

    if (filters.office) {
      parsedFilters.officeId = filters.office.id;
    }

    if (filters.date) {
      parsedFilters.eventDate = filters.date.value;
    }

    if (filters.qClub) {
      parsedFilters.qClub = filters.qClub;
    }

    if (filters.qKnowledgeCategory) {
      parsedFilters.qKnowledgeCategory = filters.qKnowledgeCategory;
    }

    return parsedFilters;
  };

  const fetchData = async (doReset = false) => {
    try {
      const actualPage = doReset ? 0 : page;
      const actualNews = doReset ? [] : news;

      const newsData = await PostService.getAllPaginated({
        type, page: actualPage, pageSize, ...parseFilters(),
      });

      const newContentState = [...actualNews, ...newsData.data];
      setNews(newContentState);
      setPage(actualPage + 1);

      if (newContentState.length === newsData.count) {
        setNoMoreItems(true);
      } else if (doReset) {
        setNoMoreItems(false);
      }

      setIsLoading(false);
    } catch (error) {
      setNews([]);
    }
  };

  useEffect(() => {
    setNews([]);
    setIsLoading(true);
    debouncedFetchData(() => fetchData(true));
  }, [type, filters]);

  const loadMore = () => {
    setIsLoading(true);
    debouncedFetchData(() => fetchData());
  };

  return (
    <>
      <PostContainer>
        {news?.map((post, idx) => (
          <PostCard post={post} key={`post_${idx}`} />
        ))}
        {!isLoading && news && news.length === 0 && (
          <div style={{ textAlign: 'center', width: '100%' }}>{i18n('NO_ITEMS')}</div>
        )}
      </PostContainer>
      {(isLoading || !noMoreItems) && (
        <div style={{ textAlign: 'center' }}>
          {isLoading && (<CircularProgress color="secondary" />)}
          <br />
          {!isLoading && !noMoreItems && (<Button variant="menu2" onClick={() => loadMore()}>{i18n('LOAD_MORE')}</Button>)}
        </div>
      )}
      <div />
    </>
  );
};

PostList.propTypes = {
  type: PropTypes.string,
  filters: PropTypes.object,
};

PostList.defaultProps = {
  type: null,
  filters: {},
};

export default PostList;
