import config from 'config';
import GenericService from './genericService';

class ConfigService extends GenericService {
  #cache = {};

  constructor() {
    super(config.API.CONFIG);
  }

  // method's wrapper for adding cache
  async getOne(type) {
    if (this.#cache[type]) {
      return this.#cache[type];
    }
    const data = await super.getOne(type);
    this.#cache[type] = data;
    return data;
  }
}

export default new ConfigService();
