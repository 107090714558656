import { useParams, useNavigate } from 'react-router-dom';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import { styled } from '@mui/material/styles';
import UserView from 'components/entities/user/view';
import TopBar from 'components/common/appLayout/topbar';
import Page from 'components/common/page';
import Button from 'components/common/button';
import Paper from 'components/common/paper';
import i18n from 'utils/i18n';
import routes from 'routes.json';

const UserContainer = styled('div')(() => ({
  '& div:fit-child': {
    width: '100%',
  },
}));

const UserPage = () => {
  const pathParams = useParams();
  const userId = Number.parseInt(pathParams.id, 10);
  const navigate = useNavigate();

  return (
    <Page>
      <TopBar><Button variant="breadcrumb-active" startIcon={<GroupOutlinedIcon />}>{i18n('PEOPLE_FROM_QUALITAS')}</Button></TopBar>
      <div className="page-body">
        <Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={() => navigate(`/${routes.TEAM}`)}>{i18n('BACK_TO_TEAM')}</Button>
        <br />
        <br />
        <Paper elevation={0}>
          <UserContainer>
            <UserView id={userId} />
          </UserContainer>
        </Paper>
      </div>
    </Page>
  );
};

export default UserPage;
