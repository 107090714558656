import { removeNull } from 'utils/object';

const toApi = ({
  image,
  name,
  description,
  url,
  category,
  countries,
}) => removeNull({
  image: image instanceof File ? image : undefined,
  name,
  description,
  url,
  appCategoryId: category?.id,
  countryIds: countries?.map((item) => (item.id)),
});

export default toApi;

export {
  toApi,
};
