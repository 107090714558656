import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
// import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import _ from 'lodash';
import i18n from 'utils/i18n';

const StyledTable = styled(Table)(({ theme }) => ({
  color: theme.palette.primary.main,
  // '& th': {
  //   color: theme.palette.primary.main,
  // }
  '& tbody tr:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  '& div.textColor': {
    color: theme.palette.primary.main,
  },
  // '& .MuiSvgIcon-root:hover': {
  //   color: `${theme.palette.primary.main} !important`,
  // },
  '& .MuiTableCell-root': {
    padding: `${theme.basicGap}px`,
    // fontSize: '0.875rem',
    lineHeight: '1.3',
    verticalAlignt: 'text-top',
  },
  '& .MuiTableHead-root': {
    '& th:first-of-type': {
      borderRadius: '8px 0px 0px 0px',
    },
    '& th:last-child': {
      borderRadius: '0px 8px 0px 0px',
    },
  },
  '.MuiButton-root': {
    width: '30px',
    height: '30px',
    minWidth: '30px',
  },
}));

const StyledDiv = styled('div')(() => ({
  padding: '5px',
  textAlign: 'center',
}));

const CustomTable = ({
  header, rows, handleClick, rowOptions, headless, handleOptionsVisiblility, headerStyle,
}) => (
  <Fragment>
    <StyledTable>
      <TableHead>
        <TableRow>
          {
            header.map((cell, i) => (
              <TableCell
                key={`th${i}`}
                sx={!headless
                  ? { ...headerStyle }
                  : {}}
              >
                {!headless && cell.display}
              </TableCell>
            ))
          }
          {
            rowOptions && (
              <TableCell sx={!headless
                ? { ...headerStyle }
                : {}}
              />
            )
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => (
          <TableRow key={`tc${i}`}>
            {
              header.map((cell, j) => {
                const CustomComponent = cell.customComponent;
                return (
                  <TableCell key={`tc${i}#${j}`} onClick={() => handleClick(row, i)}>
                    {CustomComponent
                      ? (<CustomComponent cell={_.get(row, cell.property)} row={row} />)
                      : _.get(row, cell.property)}
                  </TableCell>
                );
              })
            }
            {
              rowOptions && (
                <TableCell>
                  {handleOptionsVisiblility(row) && (
                    <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
                      {
                        rowOptions.map((opt, k) => {
                          const Icon = opt.icon;
                          return (
                            <Fragment key={`tcott${k}`}>
                              {((opt?.isVisible && opt.isVisible(row)) || !opt?.isVisible) && (
                              // <Tooltip title={opt.title}>
                              <Icon onClick={() => opt.handleClick(row, i)} />
                              // </Tooltip>
                              )}
                            </Fragment>
                          );
                        })
                      }
                    </div>
                  )}
                </TableCell>
              )
            }
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
    {
      (rows === null || rows.length === 0) && <StyledDiv>{i18n('NO_RESULTS')}</StyledDiv>
    }
  </Fragment>
);

CustomTable.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  handleClick: PropTypes.func,
  rowOptions: PropTypes.any,
  headless: PropTypes.bool,
  handleOptionsVisiblility: PropTypes.func,
  headerStyle: PropTypes.object,
};

CustomTable.defaultProps = {
  header: [],
  rows: null,
  handleClick: (() => ({})),
  rowOptions: null,
  headless: false,
  handleOptionsVisiblility: () => true,
  headerStyle: {},
};

export default CustomTable;
