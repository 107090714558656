import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const TimeLineContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  '.item-container': {
    flex: 1,
  },
  '.year-box-container': {
    display: 'flex',
    '.year-box-content': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      padding: '10px',
      borderRadius: '8px',
      width: 'fit-content',
      fontSize: '1.12rem',
      fontWeight: 500,
    },
    '.year-box-line': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',

      div: {
        flex: 1,
        width: '100%',
      },
      '& div:first-of-type': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  '.description': {
    paddingTop: `${theme.basicGap * 2}px`,
    paddingRight: `${theme.basicGap * 3}px`,
    minWidth: '150px',
  },
}));

const TimeLineItem = ({ item, isLast }) => (
  <div className="item-container">
    <div className="year-box-container">
      <div className="year-box-content">{item?.label}</div>
      {!isLast && (
        <div className="year-box-line">
          <div />
          <div />
        </div>
      )}
    </div>
    <div
      className="description"
      dangerouslySetInnerHTML={{
        __html: item.description,
      }}
    />
  </div>
);

TimeLineItem.propTypes = {
  item: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
};

const TimeLine = ({ data }) => (
  <TimeLineContainer>
    { Boolean(data) && Boolean(data.length) && (
      <>
        {data?.map((item, idx) => (
          <TimeLineItem item={item} key={`tline_${idx}`} isLast={(idx === (data.length - 1))} />
        ))}
      </>
    )}
  </TimeLineContainer>
);

TimeLine.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TimeLine;
