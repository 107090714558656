import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import PropTypes from 'prop-types';

const commonStyle = (theme) => ({
  fontSize: '1rem',
  borderRadius: '8px',
  padding: `${theme.basicGap}px ${theme.basicGap * 2}px`,
  fontWeight: 400,
  lineHeight: 1,
  '.MuiSvgIcon-root': {
    width: '1.25rem',
    height: '1.25rem',
  },
});

const BasicButton = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
}));

const MenuButton = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  textTransform: 'none',
  backgroundColor: '#fff',
  justifyContent: 'left',
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const MenuButtonActive = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  textTransform: 'none',
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.light,
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
  fontWeight: 500,
  justifyContent: 'left',
}));

const Menu2Button = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  textTransform: 'none',
  // backgroundColor: '#fff',
  justifyContent: 'left',
  // ':hover': {
  //   backgroundColor: theme.palette.primary.light,
  // },
}));

const MenuButton2Active = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  textTransform: 'none',
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.light,
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
  fontWeight: 500,
  justifyContent: 'left',
}));

const BreadCrumbButton = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  textTransform: 'none',
  ':hover': {
    backgroundColor: '#fff',
  },
}));

const BreadCrumbActive = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  textTransform: 'none',
  backgroundColor: '#fff',
  ':hover': {
    backgroundColor: '#fff',
  },
}));

const DarkButton = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  textTransform: 'none',
  backgroundColor: theme.palette.secondary.dark,
  color: '#fff',
  ':hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const LightButton = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  backgroundColor: theme.palette.secondary.main,
  color: '#fff',
  ':hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const GreyButton = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.primary.main,
  ':hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  ...commonStyle(theme),
  backgroundColor: red[700],
  color: '#fff',
  ':hover': {
    backgroundColor: red[700],
  },
}));

const CustomButton = ({
  children, variant, ...props
}) => {
  const customButtonTypes = {
    menu: MenuButton,
    'menu-active': MenuButtonActive,
    menu2: Menu2Button,
    'menu2-active': MenuButton2Active,
    breadcrumb: BreadCrumbButton,
    'breadcrumb-active': BreadCrumbActive,
    dark: DarkButton,
    light: LightButton,
    grey: GreyButton,
    red: RedButton,
  };

  const CustomButtom = customButtonTypes[variant] || BasicButton;

  return (
    <CustomButtom
      variant={Object.keys(customButtonTypes).includes(variant) ? 'text' : variant}
      {...props}
    >
      {Boolean(children) && children}
    </CustomButtom>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
};

CustomButton.defaultProps = {
  children: null,
  variant: 'text',
};

export default CustomButton;
