import config from 'config';
import cfetch from 'utils/fetch';
import routes from 'routes.json';

class AuthService {
  static async getUri() {
    const redirect = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/${routes.AZURE_LOGIN}`;
    const url = `${config.API.BASE_URL}/${config.API.AUTH.URI}?redirect=${redirect}`;

    const response = await cfetch(url);
    const responseData = await response.json();

    return responseData;
  }

  static async getToken(clientFlowData, msAuthData) {
    const url = `${config.API.BASE_URL}/${config.API.AUTH.TOKEN}?${msAuthData}`;

    const response = await cfetch(
      url,
      { method: 'POST', body: JSON.stringify({ flow: clientFlowData }), headers: { 'content-type': 'application/json' } },
    );
    const responseData = await response.json();

    return responseData;
  }
}

export default AuthService;
