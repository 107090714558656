import {
  Fragment, createContext, useContext, useState, useCallback,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';

const StyledSnackbar = styled(Snackbar)(() => ({
  '& .MuiAlert-message': {
    fontSize: '1rem',
  },
}));

const AlertContext = createContext();

const CustomTransition = (props) => <Slide {...props} direction="right" />;

const AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = useCallback((message, severity = 'success') => {
    setAlertState({ open: true, message, severity });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertState({ open: false, message: '' });
  };

  return (
    <Fragment>
      <AlertContext.Provider value={showAlert}>
        {children}
      </AlertContext.Provider>
      <StyledSnackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertState.open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionComponent={CustomTransition}
      >
        <Alert onClose={handleClose} severity={alertState.severity} sx={{ width: '100%' }}>
          {alertState.message}
        </Alert>
      </StyledSnackbar>
    </Fragment>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAlert = () => {
  const showAlert = useContext(AlertContext);
  return showAlert;
};

export {
  AlertContext,
  AlertProvider,
  useAlert,
};
