/* eslint no-prototype-builtins: 0 */

import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UndoIcon from '@mui/icons-material/Undo';
import { styled } from '@mui/material/styles';
import Button from 'components/common/button';
import PageFilters from 'components/common/pageFilters';
import i18n from 'utils/i18n';
import PostList from './list';

const PageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 3}px`,
  '.buttons': {
    display: 'flex',
    gap: `${theme.basicGap * 2}px`,
  },
}));

const TopContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

/**
 * Renders a generic post page with filters and the content
 */
const PostPage = ({
  type, visualFiltersData, otherFiltersData, buttons, buttonsOnly, onGoBack,
}) => {
  const generateFiltersState = () => {
    const allFilters = [...visualFiltersData, ...otherFiltersData];
    let state;
    if (allFilters && allFilters.length) {
      state = {};
      allFilters.forEach((filterData) => {
        state[filterData.name] = filterData?.value || null;
      });
    }
    return state;
  };

  const [filters, setFilters] = useState(generateFiltersState);
  const [contentType, setContentType] = useState(type);

  useEffect(() => {
    if (type !== contentType) {
      setContentType(type);
    }

    const newFilters = generateFiltersState();
    if (!isEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  }, [type, otherFiltersData, buttonsOnly]);

  const changeFilter = (name, value) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const resetFilters = () => setFilters(generateFiltersState());

  return (
    <PageContainer>
      <TopContainer>
        <div className="buttons">
          {onGoBack && (<Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={() => onGoBack()}>{i18n('BACK')}</Button>)}
          {buttons?.map((TopButton, idx) => <TopButton key={`post_page_btn_${idx}`} />)}
        </div>
        <PageFilters>
          { !buttonsOnly && filters && visualFiltersData?.map(({ Component, name, placeholder }, idx) => (
            <Fragment key={`post-page-filter-${idx}`}>
              { filters?.hasOwnProperty(name) && (
                <Component
                  textFieldVariant="outlined"
                  size="small"
                  placeholder={placeholder}
                  sx={{ width: '200px' }}
                  value={filters[name]}
                  onChange={(ev, v) => changeFilter(name, v)}
                />
              )}
            </Fragment>
          ))}
          {!buttonsOnly && visualFiltersData && Boolean(visualFiltersData?.length) && (
          <Button variant="menu" sx={{ width: 'fit-content', minWidth: '30px' }} onClick={() => resetFilters()}>
            <RestartAltIcon />
          </Button>
          )}
        </PageFilters>
      </TopContainer>
      {!buttonsOnly && (<PostList type={contentType} filters={filters} />)}
    </PageContainer>
  );
};

PostPage.propTypes = {
  visualFiltersData: PropTypes.array,
  type: PropTypes.string,
  otherFiltersData: PropTypes.array,
  buttons: PropTypes.array,
  buttonsOnly: PropTypes.bool, // used for render just the buttons in some cases and not break page's layout
  onGoBack: PropTypes.func,
};

PostPage.defaultProps = {
  visualFiltersData: [],
  type: null,
  otherFiltersData: [],
  buttons: [],
  buttonsOnly: false,
  onGoBack: null,
};

export default PostPage;
