import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Paper from 'components/common/paper';
import Button from 'components/common/button';
import PostService from 'services/postService';
import i18n from 'utils/i18n';
import { getUrl } from 'utils/resource';
import Breadcrumb from './breadcrumb';
import PostTag from './tag';
import PageHeader from './pageHeader';
import { getBackUrl } from './helpers';

const ViewContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 7}px`,
  flex: 1,
  '.left': {
    width: '21.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 3}px`,
    '.post-img': {
      width: '100%',
      aspectRatio: '1.5/1',
      borderRadius: '8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '.info-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.basicGap}px`,
    },
    '.info': {
      display: 'flex',
      gap: `${theme.basicGap}px`,
      '.info-item': {
        display: 'flex',
        padding: `${theme.basicGap}px ${theme.basicGap * 2}px`,
        fontSize: '0.875rem',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '8px',
        lineHeight: 1,
      },
    },
  },
  '.right': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap * 4}px`,
    '.title': {
      fontSize: '1.5rem',
    },
    '.headline': {
      fontSize: '1.125rem',
    },
  },
  figure: {
    margin: '0px',
  },
  iframe: {
    maxWidth: '100%',
  },
  img: {
    maxWidth: '100%',
    borderRadius: '8px',
  },
  figcaption: {
    textAlign: 'center',
    fontSize: '0.875rem',
  },
}));

const PostViewPage = () => {
  const [post, setPost] = useState(null);
  const pathParams = useParams();
  const postId = Number.parseInt(pathParams.id, 10);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const postData = await PostService.getOne(postId);
      setPost(postData.data);
    } catch (error) {
      setPost(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goBack = () => {
    navigate(getBackUrl(post));
  };

  return (
    <Page>
      <TopBar>
        <PageHeader>
          {post && (
            <Breadcrumb type={post.type} selectedQclub={post.qClub} />
          )}
        </PageHeader>
      </TopBar>
      <div className="page-body">
        <Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={() => goBack()}>{i18n('BACK')}</Button>
        <br />
        <br />
        <Paper elevation={0}>
          <ViewContainer>
            <div className="left">
              <div
                className="post-img"
                style={{
                  backgroundImage: `url(${getUrl(post?.image) || '/img_bg.png'})`,
                  backgroundSize: post?.image ? 'cover' : 'contain',
                }}
              />
              <div className="info-container">
                {post && post?.qClub && (
                <div className="info">
                  <PostTag type={post?.type} qclub={post?.qClub} subQClub={post?.qKnowledgeCategory} />
                </div>
                )}
                <div className="info">
                  <div className="info-item">
                    {format(new Date(post?.eventDate || post?.visibleFrom || post?.createdAt || null), 'dd/MM/yyyy')}
                  </div>
                  {
                    post?.offices?.map((office, idx) => (
                      <div className="info-item" key={`post_off_${idx}`}>
                        {office.name}
                      </div>
                    ))
                  }
                  {
                    post?.allOffices && (
                      <div className="info-item">
                        {i18n('GLOBAL')}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="right">
              <Typography className="medium title" color="secondary">{post?.title}</Typography>
              <Typography className="headline">{post?.headline}</Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.content,
                }}
              />
            </div>
          </ViewContainer>
        </Paper>
      </div>
    </Page>
  );
};

export default PostViewPage;
