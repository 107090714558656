import DataSelect from 'components/common/dataSelect';
import ConfigService from 'services/configService';

const CountrySelect = (props) => {
  const dataPromise = () => ConfigService.getOne('country');

  return (
    <DataSelect
      dataPromise={dataPromise}
      itemLabelPath="name"
      itemValuePath="id"
      {...props}
    />
  );
};

export default CountrySelect;
