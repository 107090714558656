import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PostService from 'services/postService';
import { useAlert } from 'components/common/alert';
import Dialog from 'components/common/dialog';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Button from 'components/common/button';
import i18n from 'utils/i18n';
import routes from 'routes.json';
import PostForm from './form';
import { postFormToApi } from './adapters';

const PostCreate = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const showAlert = useAlert();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      setIsSaving(true);
      const adaptedValues = postFormToApi(values);
      const created = await PostService.create(adaptedValues);

      try {
        await PostService.addImage(created?.data?.id, values?.image);
      } catch (error) {
        showAlert(error.message, 'error');
      }

      setIsSaving(false);
      navigate(`/${routes.POST.replace(':id', created?.data?.id)}`);
      showAlert(i18n('CREATED_SUCCESS', { replacements: { item: i18n('POST') } }));
    } catch (error) {
      setIsSaving(false);
      showAlert(error.message, 'error');
    }
  };

  const handleClose = () => {
    navigate(`/${routes.NEWS}`);
  };

  const confirmButtons = [{
    label: 'CONTINUE',
    componentProps: {
      variant: 'light',
      onClick: () => setIsLeaving(false),
    },
  },
  {
    label: 'LEAVE_WITHOUT_SAVING',
    componentProps: {
      variant: 'grey',
      onClick: handleClose,
    },
  },
  ];

  return (
    <Page>
      <TopBar>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px' }}>
          <Button variant="breadcrumb-active" startIcon={<AddIcon />}>{i18n('POST_FORM_POSTIN')}</Button>
          <Button variant="grey" onClick={() => setIsLeaving(true)}>{i18n('CANCEL')}</Button>
        </div>
      </TopBar>
      <div className="page-body">
        <PostForm onSubmit={handleSubmit} />
      </div>
      <Dialog open={isSaving}>
        <div style={{
          alignItems: 'center', width: '200px', display: 'flex', flexDirection: 'column', gap: '32px',
        }}
        >
          <div>
            <Typography color="primary">{i18n('SAVING_DATA')}</Typography>
          </div>
          <CircularProgress />
        </div>
      </Dialog>
      <Dialog
        title={i18n('LEAVE_FORM_TITLE')}
        buttons={confirmButtons}
        open={isLeaving}
      >
        {i18n('LEAVE_FORM')}
      </Dialog>
    </Page>
  );
};

export default PostCreate;
