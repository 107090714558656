import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Typography from '@mui/material/Typography';
import EditButton from 'components/common/editButton';
import Dialog from 'components/common/dialog';
import Button from 'components/common/button';
import { userCanEdit } from 'utils/session';
import routes from 'routes.json';
import i18n from 'utils/i18n';
import { Q_CLUBS } from 'components/entities/post/params';

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: `${theme.basicGap}px`,
  '.btn-right': {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: `${theme.basicGap}px`,
  },
}));

const canEdit = userCanEdit();

const getDialogConfig = (qClub, onSubmit, onCancel) => {
  const config = {
    title: '',
    body: '',
    buttons: [{
      label: 'CANCEL',
      componentProps: {
        variant: 'grey',
        onClick: () => onCancel(),
      },
    }],
  };

  switch (qClub) {
    case Q_CLUBS.KNOWLEDGE:
      config.title = i18n('SUGGESTIONS_QKNOWLEDGE_TITLE');
      config.body = i18n('SUGGESTIONS_QKNOWLEDGE_BODY');
      config.buttons.unshift({
        label: 'SUGGESTIONS_QKNOWLEDGE_BUTTON',
        componentProps: {
          onClick: () => onSubmit('q-knowledge@qenergy.com'),
          variant: 'light',
        },
      });
      break;
    case Q_CLUBS.COMMUNITY:
      config.title = i18n('SUGGESTIONS_QCOMMUNITY_TITLE');
      config.body = i18n('SUGGESTIONS_QCOMMUNITY_BODY');
      config.buttons.unshift({
        label: 'SUGGESTIONS_QCOMMUNITY_BUTTON',
        componentProps: {
          onClick: () => onSubmit('q-community@qenergy.com'),
          variant: 'light',
        },
      });
      break;
    case Q_CLUBS.DIVERSITY:
      config.title = i18n('SUGGESTIONS_QDIVERSITY_TITLE');
      config.body = i18n('SUGGESTIONS_QDIVERSITY_BODY');
      config.buttons.unshift({
        label: 'SUGGESTIONS_QDIVERSITY_BUTTON',
        componentProps: {
          onClick: () => onSubmit('q-diversity@qenergy.com'),
          variant: 'light',
        },
      });
      break;
    default:
      break;
  }

  return config;
};

const PageHeader = ({ children, qClub }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();

  const goToForm = () => navigate(`/${routes.POST_NEW}`);

  const mailTo = (mail) => {
    window.open(`mailto:${mail}`, '_blank');
    setDialogOpen(false);
  };

  const dialogConfig = getDialogConfig(qClub, mailTo, () => setDialogOpen(false));

  return (
    <HeaderContainer>
      <div>
        {Boolean(children) && children}
      </div>
      <div className="btn-right">
        {qClub && (
          <Button
            startIcon={<EmojiObjectsOutlinedIcon />}
            variant="breadcrumb-active"
            onClick={() => setDialogOpen(true)}
          >
            {i18n('SUGGESTIONS')}
          </Button>
        )}
        {canEdit && (
          <EditButton
            icon={AddIcon}
            iconsize="1.25rem"
            sx={{ width: '3.25rem', minWidth: '3.25rem' }}
            onClick={() => goToForm()}
          />
        )}
      </div>
      <Dialog open={dialogOpen} buttons={dialogConfig.buttons}>
        <div style={{
          width: '392px', display: 'flex', flexDirection: 'column', gap: '8px',
        }}
        >
          <Typography color="secondary" sx={{ fontSize: '1.125rem' }} className="medium">{dialogConfig.title}</Typography>
          <Typography color="grey.500" sx={{ fontStyle: 'italic' }}>{dialogConfig.body}</Typography>
        </div>
      </Dialog>
    </HeaderContainer>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node,
  qClub: PropTypes.string,
};

PageHeader.defaultProps = {
  children: null,
  qClub: null,
};

export default PageHeader;
