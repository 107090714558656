import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const EditButtonBG = styled(Button)(({
  bgcolor, iconsize, paddingscale, theme,
}) => ({
  backgroundColor: bgcolor || theme.palette.secondary.dark,
  color: '#fff',
  padding: `${theme.basicGap * (paddingscale || 1)}px`,
  borderRadius: '8px',
  ':hover': {
    backgroundColor: bgcolor || theme.palette.secondary.dark,
  },
  '.MuiSvgIcon-root': {
    fontSize: iconsize || '1.125rem',
  },
}));

const EditButtonGrey = styled(Button)(({ iconsize, paddingscale, theme }) => ({
  backgroundColor: theme.palette.grey[400],
  color: '#fff',
  padding: `${theme.basicGap * (paddingscale || 1)}px`,
  borderRadius: '8px',
  ':hover': {
    backgroundColor: theme.palette.grey[400],
  },
  '.MuiSvgIcon-root': {
    fontSize: iconsize || '1.125rem',
  },
}));

const EditButton = ({
  variant, icon, iconsize, bgcolor, paddingscale, size, ...props
}) => {
  let Component = EditButtonBG;
  const Icon = icon;

  if (variant === 'disabled') {
    Component = EditButtonGrey;
  }
  return (
    <Component
      bgcolor={bgcolor}
      paddingscale={paddingscale}
      iconsize={iconsize}
      sx={{
        verticalAlign: 'middle', width: size, height: size, minWidth: size,
      }}
      {...props}
    >
      <Icon sx={{
        verticalAlign: 'middle',
      }}
      />

    </Component>
  );
};

EditButton.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.any,
  bgcolor: PropTypes.string,
  paddingscale: PropTypes.number,
  iconsize: PropTypes.string,
  size: PropTypes.string,
};

EditButton.defaultProps = {
  variant: null,
  icon: EditOutlinedIcon,
  bgcolor: null,
  paddingscale: 1,
  iconsize: null,
  size: '34px',
};

export default EditButton;
