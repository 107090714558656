import PropTypes from 'prop-types';
import { Field } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { getContentKey } from 'utils/content';

const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 2}px`,
  alignItems: 'flex-end',
}));

const FormItem = ({
  isAdminView, data, itemPrefix, itemKey, errors, extra, typoVariant, color, fieldSx, extraClass,
}) => (
  <>
    { !isAdminView && (
    <Typography color={color} variant={typoVariant} className="title">
      {getContentKey(data, `${itemPrefix}${itemKey}`)}
      {' '}
      {extra}
    </Typography>
    )}
    { isAdminView && (
      <InputContainer className={extraClass}>
        <Field
          as={TextField}
          variant="standard"
          name={itemKey}
          error={Boolean(errors[itemKey])}
          helperText={errors[itemKey]}
          sx={fieldSx}
        />
        <Typography className={extraClass}>
          {extra}
        </Typography>
      </InputContainer>
    )}
  </>
);

FormItem.propTypes = {
  isAdminView: PropTypes.bool,
  data: PropTypes.array,
  itemPrefix: PropTypes.string,
  itemKey: PropTypes.string,
  errors: PropTypes.object,
  extra: PropTypes.string,
  typoVariant: PropTypes.string,
  fieldSx: PropTypes.object,
  color: PropTypes.string,
  extraClass: PropTypes.string,
};

FormItem.defaultProps = {
  isAdminView: false,
  data: {},
  itemPrefix: '',
  itemKey: '',
  errors: {},
  extra: '',
  typoVariant: 'h6',
  fieldSx: {},
  color: 'secondary',
  extraClass: null,
};

export default FormItem;
