import { styled } from '@mui/material/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';

import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'components/common/button';
import i18n from 'utils/i18n';
import routes from 'routes.json';
import Logo from 'components/common/logo';

import PARAMS from './params';

const SidebarContainer = styled('div')(({ open, theme }) => ({
  height: '100%',
  padding: `${theme.basicGap * 3}px`,
  paddingRight: `${theme.basicGap * 2}px`,
  width: open ? PARAMS.SIDEBAR_WIDTH : 'fit-content',
  [theme.breakpoints.down('qualitas')]: {
    width: open ? PARAMS.SIDEBAR_WIDTH_REDUCED : 'fit-content',
  },
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 4}px`,
  '.sidebar-content': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.basicGap}px`,
    '.MuiButtonBase-root': {
      width: '100%',
    },
  },
  '.sidebar-divider': {
    padding: `${theme.basicGap * 2}px 0px`,
    width: '100%',
  },
  'sidebar-social': {
    display: 'flex',
    gap: `${theme.basicGap * 2}px`,
  },
  '.logo': {
    height: `${PARAMS.TOPBAR_CONTENT_HEIGHT}px`,
    minHeight: `${PARAMS.TOPBAR_CONTENT_HEIGHT}px`,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

const SideBar = () => {
  const buttonsData = [{
    icon: HomeOutlinedIcon,
    label: 'HOME',
    url: routes.APP_ROOT,
  }, {
    icon: AccountCircleOutlinedIcon,
    label: 'MY_AREA',
    url: routes.APP_ROOT,
  }, {
    icon: GridViewIcon,
    label: 'APPS',
    url: routes.APPS,
  }, {
    icon: SettingsOutlinedIcon,
    label: 'RESOURCES',
    url: routes.RESOURCES.replace(':id', 0),
  }, {
    icon: GroupOutlinedIcon,
    label: 'TEAM',
    url: routes.TEAM,
  }, {
    icon: InfoOutlinedIcon,
    label: 'ABOUT_QE',
    url: routes.ABOUT,
  }, {
    icon: 'divider',
  }, {
    icon: FeedOutlinedIcon,
    label: 'NEWS',
    url: routes.NEWS,
  }, {
    icon: CalendarTodayOutlinedIcon,
    label: 'EVENTS',
    url: routes.EVENTS,
  }, {
    icon: LocalLibraryOutlinedIcon,
    label: 'Q_CLUBS',
    url: routes.QCLUBS.replace(':qClub?/:subQclub?', ''),
  }, {
    icon: 'divider',
  }, {
    icon: MailOutlineOutlinedIcon,
    label: 'MAILBOX',
    url: routes.APP_ROOT,
  }, {
    icon: AssignmentOutlinedIcon,
    label: 'POLICIES',
    url: routes.APP_ROOT,
  }];

  const location = useLocation();

  const getSelectedIdx = () => buttonsData.findIndex((buttonData) => {
    let pathMatches = location.pathname.match(/^\/([^/]*).*$/);
    pathMatches = pathMatches || [];
    return pathMatches ? ((pathMatches[1].replace('user', 'team') || routes.APP_ROOT) === (buttonData.url || '')) : false;
  });

  const [selectedButton, setSelectedButton] = useState();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const idx = getSelectedIdx();
    setSelectedButton(idx);
  }, [location]);

  const onButtonClick = (url) => {
    navigate(url);
  };

  const buttonGenerator = ({ icon, label, url }, idx) => {
    if (icon === 'divider') {
      return (
        <div className="sidebar-divider" key={`menu-btn-${idx}`}><Divider /></div>
      );
    }
    const Icon = icon;
    return (
      <Button
        variant={selectedButton === idx ? 'menu-active' : 'menu'}
        onClick={() => onButtonClick(url)}
        key={`menu-btn-${idx}`}
        sx={open ? {} : { width: 'fit-content', justifyContent: 'center' }}
      >
        <Icon />
        { open && (<span style={{ paddingLeft: '8px' }}>{i18n(label)}</span>)}
      </Button>
    );
  };

  const generatedButtons = buttonsData.map(buttonGenerator);

  const onMenuToggle = () => setOpen((prev) => !prev);

  return (
    <SidebarContainer open={open}>
      <div className="logo">
        <Logo short={!open} />
        <Tooltip title={i18n('SHOW_MENU')}>
          <MenuIcon color="secondary" onClick={() => onMenuToggle()} sx={{ cursor: 'pointer', verticalAlign: 'middle' }} />
        </Tooltip>
      </div>
      <div className="sidebar-content">
        {generatedButtons}
        <div className="sidebar-divider"><Divider /></div>
        <Fade in={open}>
          <div className="sidebar-social" style={{ display: 'flex', gap: '15px' }}>
            <a href="https://www.linkedin.com/company/qualitas-energy-global" target="_blank" rel="noreferrer" style={{ color: 'inherit' }}><LinkedInIcon sx={{ width: '1.25rem' }} /></a>
            <a href="https://qualitasenergy.com/" target="_blank" rel="noreferrer" style={{ color: 'inherit' }}><LanguageIcon sx={{ width: '1.25rem' }} /></a>
          </div>
        </Fade>
      </div>
    </SidebarContainer>
  );
};

export default SideBar;
