import React, {
  useState, useEffect, Fragment,
} from 'react';
import Table from 'components/common/table';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
  styled, createTheme, ThemeProvider, useTheme,
} from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { enUS } from '@mui/material/locale';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { debounce, isEqual } from 'lodash';
import i18n from 'utils/i18n';
import usePrevious from 'utils/usePrevious';
import Paper from 'components/common/paper';
import CustomButton from './button';

const StyledDiv = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: `${theme.basicGap * 2}px`,
  flexWrap: 'wrap',
  paddingBottom: `${theme.basicGap * 2}px`,
  '.table-find': {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '& .MuiInputBase-root': {
    padding: '0px',
    borderRadius: '8px',
  },
  ' & fieldset': {
    border: 'none',
  },
  ' & .MuiInputBase-input': {
    padding: `${theme.basicGap}px`,
  },
  // '.MuiAutocomplete-root': {
  //   minWidth: '200px',
  // },
  '.MuiAutocomplete-inputRoot': {
    backgroundColor: '#fff',
    padding: `${theme.basicGap}px`,
    flex: 1,
    maxWidth: '250px',
    '& .MuiChip-root': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '.filters': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: `${theme.basicGap}px`,
    '.reset-btn': {
      justifyContent: 'center',
    },
  },
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

// debounced side effect for find  value change, declared outside for avoiding function redeclare at every render
const debouncedFindReload = debounce((key, value, setPagination, pagination) => {
  sessionStorage.setItem(key, value);
  setPagination({ ...pagination, page: 0 });
}, 1000);

// debounced side effect for filters value change, declared outside for avoiding function redeclare at every render
const debouncedFilterReload = debounce((setPagination, pagination) => {
  setPagination({ ...pagination, page: 0 });
}, 1000);

/**
 * Component for printing full tables from a promise
 * @param dataPromise The promise, should return count and data properties
 * @param onNew How to handle on new click
 * @param shouldFind Include searchbox or not
 * @param shouldPaginate Include pagination or not
 * @param rowOptions For printing row options
 * @param toolbarExtraComponent Extra component added to toolbar
 */
const DataTable = ({
  dataPromise,
  onNew,
  shouldFind,
  shouldPaginate,
  rowOptions,
  toolbarExtraComponent,
  title,
  filters,
  summaryComponent,
  setUpdateCallback,
  findInputPlaceholder,
  ...props
}) => {
  const storedFindKey = `${title || 'generic'}_table_find`;
  const storedFind = sessionStorage.getItem(storedFindKey);
  const [data, setData] = useState(null);
  const [find, setFind] = useState(title ? storedFind || null : null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    rowsPerPage: 100,
    page: 0,
  });
  const [dataFilters, setDataFilters] = useState({});
  const prevTableState = usePrevious({ find, pagination, dataFilters });

  // data fetching fn
  const fetchData = async () => {
    if (dataPromise) {
      setIsLoading(true);

      const parsedFilters = {};
      filters?.forEach((filter) => {
        const parse = filter?.parseValue;
        parsedFilters[filter?.name] = dataFilters[filter?.name]?.map(parse);
      });

      const promiseResult = await dataPromise({
        find,
        page: pagination.page,
        pageSize: pagination.rowsPerPage,
        ...parsedFilters,
      });

      setData(promiseResult);
      setIsLoading(false);
    }
  };

  // effect for supply a callback to force a re-render from an upper scope
  useEffect(() => {
    const updCb = () => {
      setPagination({ ...pagination });
    };

    setUpdateCallback(updCb);
  }, []);

  // effect when pagination value changes and do a data re-fetch
  useEffect(() => {
    fetchData();
  }, [pagination]);

  // effect when find value changes and do a data re-fetch
  useEffect(() => {
    const effect = async () => {
      const prev = prevTableState?.find;
      if (find !== undefined && prev !== undefined && find !== prev) {
        debouncedFindReload(storedFindKey, find, setPagination, pagination);
      }
    };
    effect();
  }, [find]);

  // effect when filters value changes and do a data re-fetch
  useEffect(() => {
    const effect = async () => {
      const prev = prevTableState?.dataFilters;
      if (prev !== undefined && !isEqual(prev, dataFilters)) {
        debouncedFilterReload(setPagination, pagination);
      }
    };
    effect();
  }, [dataFilters]);

  const findHandleChange = (event) => {
    setFind(event.target.value);
  };

  const handleOnPageChange = (ev, page) => {
    setPagination({ ...pagination, page });
  };

  const handleOnRowsPerPageChange = (ev) => {
    setPagination({ ...pagination, rowsPerPage: ev.target.value });
  };

  const theme = useTheme();
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, enUS),
    [theme],
  );

  const ToolbarExtra = toolbarExtraComponent;
  const Summary = summaryComponent;

  const onFilterChange = (name, value) => {
    const newFilters = { ...dataFilters };
    newFilters[name] = value;
    setDataFilters(newFilters);
  };

  const onFiltersReset = () => {
    setDataFilters({});
  };

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <StyledDiv>
        {filters && (
          <div className="filters">
            {filters?.map(
              ({ name, Component, componentProps }, idx) => (
                <Component {...componentProps} key={`dt_filter_${idx}`} value={dataFilters[name] || []} onChange={(ev, value) => onFilterChange(name, value)} />
              ),
            )}
            <CustomButton variant="menu" className="reset-btn" onClick={onFiltersReset} sx={{ padding: '8px', minWidth: 'inherit' }}><RestartAltIcon /></CustomButton>
          </div>
        )}
        {
          shouldFind && (
            <div className="table-find">
              <TextField
                id="standard-start-adornment"
                InputProps={{
                  startAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                }}
                placeholder={findInputPlaceholder || i18n('SEARCH')}
                value={find || ''}
                onChange={findHandleChange}
              />
            </div>
          )
        }
        {
          onNew && (
            <div>
              <Button variant="outlined" endIcon={<AddIcon />} onClick={onNew}>
                {i18n('NEW')}
              </Button>
            </div>
          )
        }
        {
          toolbarExtraComponent && <ToolbarExtra />
        }
      </StyledDiv>
      {isLoading && <CircularProgress />}
      {!isLoading
        && (
          <Fragment>
            <Paper elevation={0}>
              {summaryComponent && (<Summary data={data} />)}
              <Table {...props} rows={data.data} rowOptions={rowOptions} />
              <ThemeProvider theme={themeWithLocale}>
                {shouldPaginate && (
                <StyledPagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={data.count}
                  rowsPerPage={pagination.rowsPerPage}
                  page={pagination.page}
                  onPageChange={handleOnPageChange}
                  onRowsPerPageChange={handleOnRowsPerPageChange}
                />
                )}
              </ThemeProvider>
            </Paper>
          </Fragment>
        )}
    </div>
  );
};

DataTable.propTypes = {
  dataPromise: PropTypes.func, // Data printed from the result of a promise
  onNew: PropTypes.func, // onNew callback
  shouldFind: PropTypes.bool, // if searchbox is shown
  shouldPaginate: PropTypes.bool, // if pagination is shown
  rowOptions: PropTypes.any, // Row level options
  toolbarExtraComponent: PropTypes.any, // add extra components to toolbar
  title: PropTypes.string, // for session storage key (find value)
  filters: PropTypes.array, // Filters { name : name, Component: Component to render, filterProps: props to component,parseValue : func(value) => value to back-end}
  summaryComponent: PropTypes.any, // a component which receive data and renders before table
  setUpdateCallback: PropTypes.func, // callback to set update function to an upper scope
  findInputPlaceholder: PropTypes.string, // the searchbox input's custom placeholder
};

DataTable.defaultProps = {
  dataPromise: null,
  onNew: null,
  shouldFind: true,
  shouldPaginate: true,
  rowOptions: null,
  toolbarExtraComponent: null,
  title: null,
  filters: null,
  summaryComponent: null,
  setUpdateCallback: () => {},
  findInputPlaceholder: null,
};

export default DataTable;
