import i18n from './i18n';

class RequestError extends Error {
  constructor(error, payload = null) {
    // We receive an errorCode, optionally with extra fields
    let errorMessage = i18n(error.errorCode, { error: true });

    // The i18n message can contain placeholders
    // If found, we try to replace them with the extra fields recieved
    Object.keys(error).forEach((key) => {
      errorMessage = errorMessage.replace(`{${key}}`, error[key]);
    });

    // replace error placeholder with payload properties
    if (payload) {
      let parsedPayload;
      try {
        parsedPayload = JSON.parse(payload);
      } catch {
        parsedPayload = {};
      }

      Object.keys(parsedPayload)?.forEach((key) => {
        errorMessage = errorMessage.replace(`{${key}}`, parsedPayload[key]);
      });
    }

    super(errorMessage);
    this.code = error.errorCode;
    this.statusCode = error.statusCode;
    this.responseResult = error;
  }
}

export default RequestError;
