import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from 'components/common/select';
import DataSelect from 'components/common/dataSelect';
import i18n from 'utils/i18n';
import { USER_ROLES } from './params';

const RoleSelect = ({ multiple, ...props }) => {
  const data = Object.keys(USER_ROLES)?.map((k) => ({ id: USER_ROLES[k], name: i18n(k) }));

  return (
    <>
      { multiple && (
      <DataSelect
        dataObject={data}
        itemLabelPath="name"
        itemValuePath="id"
        multiple={multiple}
        {...props}
      />
      )}
      {!multiple && (
        <Select sx={{ minWidth: '150px' }} {...props}>
          {
          data?.map((role, idx) => (
            <MenuItem value={role.id} key={`state_select_${idx}`}>{role.name}</MenuItem>
          ))
        }
        </Select>
      )}
    </>
  );
};

RoleSelect.propTypes = {
  multiple: PropTypes.bool,
};

RoleSelect.defaultProps = {
  multiple: false,
};

export default RoleSelect;
