import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import TextField from '@mui/material/TextField';
import Paper from 'components/common/paper';
import Button from 'components/common/button';
import ImageInput from 'components/common/imageInput';
import i18n from 'utils/i18n';
import isValidFileType from 'utils/validImageType';
import AutoSubmit from 'components/common/autosubmit';

/**
 * Renders the form for common fields
 */
const CommonForm = ({
  goBack, goNext, onSubmit, editing, header,
}) => {
  const initialValues = {
    title: editing?.title || '',
    headline: editing?.headline || '',
    image: editing?.image || null,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(i18n('MANDATORY')),
    headline: Yup.string().required(i18n('MANDATORY')),
    image: Yup
      .mixed()
      .test(
        'is-valid-type',
        i18n('INVALID_IMAGE'),
        (value) => typeof value === 'string' || isValidFileType(value && value.name.toLowerCase(), 'image'),
      ),
  });

  const wrappedGoNext = async (validate, values) => {
    onSubmit(values);
    const result = await validate();
    if (!Object.keys(result)?.length) {
      goNext();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors, setFieldValue, values, validateForm,
      }) => (
        <Form>
          <AutoSubmit onSubmit={onSubmit} />
          <Paper elevation={0}>
            <div className="form-content">
              {header}
              <div className="form-item">
                <Typography>{i18n('POST_FORM_ADD_TITLE')}</Typography>
                <Field
                  as={TextField}
                  name="title"
                  variant="outlined"
                  placeholder={i18n('TITLE')}
                  size="small"
                  error={Boolean(errors.title)}
                  helperText={errors.title}
                />
              </div>
              <div className="form-item">
                <Typography>{i18n('POST_FORM_ADD_HEADLINE')}</Typography>
                <Field
                  as={TextField}
                  name="headline"
                  variant="outlined"
                  placeholder={i18n('HEADLINE')}
                  size="small"
                  error={Boolean(errors.headline)}
                  helperText={errors.headline}
                />
              </div>
              <div className="form-item">
                <Typography>{i18n('POST_FORM_ADD_IMAGE')}</Typography>
                <Field
                  as={ImageInput}
                  name="image"
                  variant="standard"
                  label={i18n('IMAGE')}
                  error={Boolean(errors.image)}
                  helperText={errors.image}
                  value={values.image}
                  width="21rem"
                  aspectRatio="1.5/1"
                  onChange={(value) => setFieldValue('image', value)}
                />
              </div>
            </div>
          </Paper>
          <div className="bottom-bar">
            <Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={() => goBack()}>{i18n('BACK')}</Button>
            <Button variant="light" sx={{ textTransform: 'none' }} onClick={() => wrappedGoNext(validateForm, values)}>{i18n('NEXT_STEP')}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CommonForm.propTypes = {
  goBack: PropTypes.func,
  goNext: PropTypes.func,
  onSubmit: PropTypes.func,
  editing: PropTypes.object,
  header: PropTypes.node,
};

CommonForm.defaultProps = {
  goBack: () => {},
  goNext: () => {},
  onSubmit: () => {},
  editing: null,
  header: null,
};

export default CommonForm;
