import {
  BrowserRouter,
  Routes, Route,
  Navigate,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import routes from 'routes.json';

import { getSessionData, setSessionCookie } from 'utils/session';
import UserService from 'services/userService';

import { LoginPage, AzureLogin } from 'pages/login';
import AppLayout from 'components/common/appLayout';
import HomePage from 'pages/home';
import AboutPage from 'pages/about';
import TeamPage from 'pages/team';
import AppsPage from 'pages/apps';
import UserPage from 'pages/user';
import ResourcesPage from 'pages/resources';
import SettingsPage from 'pages/settings';
import NewsPage from 'pages/posts/news';
import EventsPage from 'pages/posts/events';
import PostViewPage from 'pages/posts/view';
import QclubsPage from 'pages/posts/qclubs';
import PostCreate from 'pages/posts/create';
import PostEdit from 'pages/posts/edit';

const Private = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidUser, setIsValidUser] = useState(false);

  // check stored auth against API user endpoint
  const checkLoggedUser = async () => {
    const sessionData = getSessionData();
    if (sessionData) {
      try {
        await UserService.getOne(sessionData?.user?.id);
        setSessionCookie(sessionData?.token);
        setIsValidUser(true);
      } catch (error) {
        setIsValidUser(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsValidUser(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkLoggedUser();
  }, []);

  return (
    <>
      {isLoading && (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
      }}
      >
        <CircularProgress color="secondary" />
      </div>
      )}
      { !isLoading && isValidUser && children}
      { !isLoading && !isValidUser && (<Navigate to={routes.LOGIN} />)}
    </>
  );
};

Private.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path={routes.LOGIN} element={<LoginPage />} />
      <Route path={routes.AZURE_LOGIN} element={<AzureLogin />} />
      <Route path={routes.APP_ROOT} element={(<Private><AppLayout /></Private>)}>
        <Route index element={<HomePage />} />
        <Route path={routes.ABOUT} element={<AboutPage />} />
        <Route path={routes.TEAM} element={<TeamPage />} />
        <Route path={routes.APPS} element={<AppsPage />} />
        <Route path={routes.USER} element={<UserPage />} />
        <Route path={routes.RESOURCES} element={<ResourcesPage />} />
        <Route path={routes.SETTINGS} element={<SettingsPage />} />
        <Route path={routes.NEWS} element={<NewsPage />} />
        <Route path={routes.EVENTS} element={<EventsPage />} />
        <Route path={routes.POST_NEW} element={<PostCreate />} />
        <Route path={routes.POST} element={<PostViewPage />} />
        <Route path={routes.QCLUBS} element={<QclubsPage />} />
        <Route path={routes.POST_EDIT} element={<PostEdit />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;
