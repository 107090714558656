import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Button from 'components/common/button';
import i18n from 'utils/i18n';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    borderRadius: '8px',
  },
  '.MuiDialogTitle-root': {
    padding: `${theme.basicGap * 3}px`,
    paddingBottom: '0px',
  },
  '.MuiDialogContent-root': {
    padding: `${theme.basicGap * 3}px`,
  },
}));

const ButtonDiv = styled('div')(({ position, theme }) => ({
  display: 'flex',
  justifyContent: position,
  paddingTop: `${theme.basicGap * 4}px`,
  gap: `${theme.basicGap * 2}px`,
}));

const CustomDialog = ({
  buttons, title, children, buttonsPosition, ...props
}) => (
  <StyledDialog
    {...props}
  >
    {title && (<DialogTitle sx={{ fontSize: '1.12rem', fontWeight: '400' }}>{title}</DialogTitle>)}
    <DialogContent sx={{ color: 'grey.special' }}>
      {children}
      {buttons && (
      <ButtonDiv position={buttonsPosition}>
        {
            buttons?.map(({ label, componentProps }, idx) => <Button {...componentProps} key={`confirm_btn_${idx}`}>{i18n(label)}</Button>)
          }
      </ButtonDiv>
      )}
    </DialogContent>
  </StyledDialog>
);

CustomDialog.propTypes = {
  buttons: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.node,
  buttonsPosition: PropTypes.string,
};

CustomDialog.defaultProps = {
  buttons: null,
  title: null,
  children: null,
  buttonsPosition: 'flex-start',
};

export default CustomDialog;
