import config from 'config';
import GenericService from './genericService';

class ResourceService extends GenericService {
  constructor() {
    super(config.API.RESOURCE);
  }
}

export default new ResourceService();
