import { styled } from '@mui/material/styles';

const PageFilters = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap}px`,
  justifyContent: 'flex-end',
  '& .MuiInputBase-root': {
    padding: '0px',
    borderRadius: '8px',
  },
  ' & fieldset': {
    border: 'none',
  },
  ' & .MuiInputBase-input': {
    padding: `${theme.basicGap}px`,
  },
  '.MuiAutocomplete-inputRoot': {
    backgroundColor: '#fff',
    padding: `${theme.basicGap}px`,
  },
}));

export default PageFilters;
