import langEn from './en.json';
import langEs from './es.json';
import langEnError from './en_error.json';
import langEsError from './es_error.json';

const i18n = (messageCode, options = {}) => {
  const {
    lang = 'en', error = false, replacements = {}, genericLabel = true, indicator = true,
  } = options;
  let messageSet;

  if (error) {
    if (lang === 'es') {
      messageSet = langEsError;
    } else if ((lang === 'en')) {
      messageSet = langEnError;
    }

    return `${genericLabel ? `${messageSet.ERROR_GENERIC}:` : ''} ${messageSet[messageCode] || ''}`;
  }

  if (lang === 'es') {
    messageSet = langEs;
  } else if ((lang === 'en')) {
    messageSet = langEn;
  }

  let message = messageSet[messageCode] || `${indicator ? 'MSG:' : ''}${messageCode}`;

  if (Object.keys(replacements).length !== 0 && Object.prototype.toString.call(message) === '[object String]') {
    Object.entries(replacements).forEach(([k, v]) => {
      message = message.replaceAll(`{${k}}`, v);
    });
  }

  return message;
};

export default i18n;
