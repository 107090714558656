import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Page from 'components/common/page';
import TopBar from 'components/common/appLayout/topbar';
import Paper from 'components/common/paper';
import { getSessionData, handleLogout } from 'utils/session';
import { getUrl } from 'utils/resource';
import { Typography } from '@mui/material';
import i18n from 'utils/i18n';

const PageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.basicGap * 2}px`,
}));

const InfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.basicGap * 2}px`,
  fontSize: '1.125rem',
  alignItems: 'center',
  '.MuiSvgIcon-root': {
    width: '1.25rem',
    height: '1.25rem',
  },
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  cursor: 'pointer',
  minHeight: '86px',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SettingsPage = () => {
  const user = getSessionData()?.user;

  return (
    <Page>
      <TopBar />
      <div className="page-body">
        <PageContainer>
          <CustomPaper elevation={0}>
            <InfoContainer>
              <Avatar sx={{ width: '55px', height: '55px' }} src={getUrl(user.azureProfilePhoto)} />
              <div>
                {user?.azureName}
                <Typography sx={{ color: 'grey.special', fontSize: '0.875' }}>{i18n(user?.role?.toUpperCase())}</Typography>
              </div>
            </InfoContainer>
          </CustomPaper>
          <CustomPaper elevation={0}>
            <InfoContainer>
              <BugReportOutlinedIcon />
              {i18n('REPORT_BUG')}
            </InfoContainer>
          </CustomPaper>
          <CustomPaper elevation={0}>
            <InfoContainer>
              <MapsUgcOutlinedIcon />
              {i18n('LEAVE_FEEDBACK')}
            </InfoContainer>
          </CustomPaper>
          <CustomPaper elevation={0} onClick={handleLogout}>
            <InfoContainer>
              <LogoutOutlinedIcon />
              {i18n('LOG_OUT')}
            </InfoContainer>
          </CustomPaper>
        </PageContainer>
      </div>
    </Page>
  );
};

export default SettingsPage;
