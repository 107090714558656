import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Paper from 'components/common/paper';
import OfficeSelect from 'components/entities/office/select';
import Button from 'components/common/button';
import QclubSelect from 'components/entities/post/qclubSelect';
import ContentTypeSelect from 'components/entities/post/contentTypeSelect';
import QknowledgeCategorySelect from 'components/entities/post/qKnowledgeCategorySelect';
import i18n from 'utils/i18n';
import AutoSubmit from 'components/common/autosubmit';
import { Q_CLUBS, CONTENT_TYPES, Q_KNOWLEDGE_CATEGORIES } from 'components/entities/post/params';

const KNOWLEDGE_CATEGORIES_NOT_EVENT = [Q_KNOWLEDGE_CATEGORIES.EDUCATION, Q_KNOWLEDGE_CATEGORIES.REPORT, Q_KNOWLEDGE_CATEGORIES.NEWS];

const isCategoryAnEvent = (category) => !KNOWLEDGE_CATEGORIES_NOT_EVENT.includes(category);

/**
 * Renders the form for content type qClub
 */
const QclubForm = ({
  goBack, goNext, onSubmit, editing, header, editionMode,
}) => {
  const initialValues = {
    qClub: editing?.qClub || null,
    type: editing?.type || null,
    eventDate: editing?.eventDate || null,
    offices: editing?.offices || [],
    audienceOffices: editing?.audienceOffices || [],
    qKnowledgeCategory: editing?.qKnowledgeCategory || null,
  };

  const validationSchema = Yup.object().shape({
    qClub: Yup.object().required(i18n('MANDATORY')),
    type: Yup.object().nullable().when(
      'qClub',
      {
        is: (val) => val?.value !== Q_CLUBS.KNOWLEDGE,
        then: (schema) => schema.required(i18n('MANDATORY')),
        otherwise: (schema) => schema,
      },
    ),
    eventDate: Yup.date().nullable().when(
      'type',
      {
        is: (val) => val?.value === CONTENT_TYPES.EVENT,
        then: (schema) => schema.required(i18n('MANDATORY')),
        otherwise: (schema) => schema,
      },
    ),
    qKnowledgeCategory: Yup.object().nullable().when(
      'qClub',
      {
        is: (val) => val?.value === Q_CLUBS.KNOWLEDGE,
        then: (schema) => schema.required(i18n('MANDATORY')),
        otherwise: (schema) => schema,
      },
    ),
  });

  const wrappedGoNext = async (validate, values) => {
    onSubmit(values);
    const result = await validate();
    if (!Object.keys(result)?.length) {
      goNext();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors, setFieldValue, values, validateForm,
      }) => (
        <Form>
          <AutoSubmit onSubmit={onSubmit} />
          <Paper elevation={0}>
            <div className="form-content max-width">
              {header}
              <div className="form-item">
                <Typography>{i18n('POST_FORM_SELECT_QCLUB')}</Typography>
                <Field
                  as={QclubSelect}
                  name="qClub"
                  textFieldVariant="outlined"
                  placeholder={i18n('Q_CLUB')}
                  size="small"
                  error={Boolean(errors.qClub)}
                  helperText={errors.qClub}
                  value={values.qClub}
                  onChange={(ev, value) => {
                    setFieldValue('qClub', value);
                    setFieldValue('type', null);
                  }}
                />
              </div>
              {values?.qClub && (
              <div className="form-item">
                <Typography>{i18n('POST_FORM_SELECT_TYPE')}</Typography>
                {(values.qClub.value === Q_CLUBS.KNOWLEDGE) && (
                  <Field
                    as={QknowledgeCategorySelect}
                    name="qKnowledgeCategory"
                    textFieldVariant="outlined"
                    placeholder={i18n('CONTENT')}
                    size="small"
                    error={Boolean(errors.qKnowledgeCategory)}
                    helperText={errors.qKnowledgeCategory}
                    value={values.qKnowledgeCategory}
                    onChange={(ev, value) => {
                      setFieldValue('qKnowledgeCategory', value);
                      if (isCategoryAnEvent(value?.value)) {
                        setFieldValue('type', { value: CONTENT_TYPES.EVENT, label: i18n('EVENT') });
                      } else {
                        setFieldValue('type', null);
                      }
                    }}
                    sx={{
                      '.MuiInputBase-root': {
                        maxWidth: '26rem !important',
                      },
                    }}
                  />
                )}
                {(values.qClub.value !== Q_CLUBS.KNOWLEDGE) && (
                  <Field
                    as={ContentTypeSelect}
                    name="type"
                    textFieldVariant="outlined"
                    placeholder={i18n('CONTENT')}
                    size="small"
                    error={Boolean(errors.type)}
                    helperText={errors.type}
                    value={values.type}
                    onChange={(ev, value) => setFieldValue('type', value)}
                  />
                )}
              </div>
              )}
              {values?.type && values.type.value === CONTENT_TYPES.EVENT && (
                <div className="form-item">
                  <Typography>{i18n('POST_FORM_SELECT_EVENTDATE')}</Typography>
                  <Field
                    format="dd/MM/yyyy"
                    as={DatePicker}
                    name="eventDate"
                    value={values.eventDate}
                    onChange={(value) => setFieldValue('eventDate', value ? new Date(value?.setHours(23, 59)) : value)}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: Boolean(errors.eventDate),
                        helperText: errors.eventDate,
                      },
                    }}
                  />
                </div>
              )}
              <div className="form-item">
                <Typography>{i18n('POST_FORM_SELECT_OFFFICES')}</Typography>
                <Typography sx={{ fontSize: '0.875rem', color: 'grey.600' }}>{i18n('POST_FORM_OFFICE_HELP')}</Typography>
                <Field
                  as={OfficeSelect}
                  name="offices"
                  textFieldVariant="outlined"
                  placeholder={i18n('OFFICES')}
                  size="small"
                  error={Boolean(errors.offices)}
                  helperText={errors.offices}
                  value={values.offices}
                  onChange={(ev, value) => setFieldValue('offices', value)}
                  disableCloseOnSelect
                  multiple
                  limitTags={0}
                  renderTags={(value) => (
                    <>
                      +
                      {value.length}
                    </>
                  )}
                />
              </div>
              <div className="form-item">
                <Typography>{i18n('POST_FORM_SELECT_AUDIENCE')}</Typography>
                <Typography sx={{ fontSize: '0.875rem', color: 'grey.600' }}>{i18n('POST_FORM_OFFICE_HELP')}</Typography>
                <Field
                  as={OfficeSelect}
                  name="audienceOffices"
                  textFieldVariant="outlined"
                  placeholder={i18n('OFFICES')}
                  size="small"
                  error={Boolean(errors.audienceOffices)}
                  helperText={errors.audienceOffices}
                  value={values.audienceOffices}
                  onChange={(ev, value) => setFieldValue('audienceOffices', value)}
                  disableCloseOnSelect
                  multiple
                  limitTags={0}
                  renderTags={(value) => (
                    <>
                      +
                      {value.length}
                    </>
                  )}
                />
              </div>

            </div>
          </Paper>
          <div className="bottom-bar" style={{ justifyContent: editionMode ? 'flex-end' : 'space-between' }}>
            {!editionMode && (<Button variant="breadcrumb-active" startIcon={<UndoIcon />} onClick={() => goBack()}>{i18n('BACK')}</Button>)}
            <Button variant="light" sx={{ textTransform: 'none' }} onClick={() => wrappedGoNext(validateForm, values)}>{i18n('NEXT_STEP')}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

QclubForm.propTypes = {
  goBack: PropTypes.func,
  goNext: PropTypes.func,
  onSubmit: PropTypes.func,
  editing: PropTypes.object,
  header: PropTypes.node,
  editionMode: PropTypes.bool,
};

QclubForm.defaultProps = {
  goBack: () => {},
  goNext: () => {},
  onSubmit: () => {},
  editing: null,
  header: null,
  editionMode: false,
};

export default QclubForm;
