import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

const CustomSelect = ({
  helperText, label, children, fullWidth, ...props
}) => (
  <FormControl fullWidth={fullWidth}>
    <InputLabel>{label}</InputLabel>
    <Select {...props} label={label}>
      {children}
    </Select>
    <FormHelperText sx={{
      color: 'error.main',
    }}
    >
      {helperText}

    </FormHelperText>
  </FormControl>
);

CustomSelect.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
};

CustomSelect.defaultProps = {
  helperText: '',
  label: '',
  children: () => (<Fragment />),
  fullWidth: false,
};

export default CustomSelect;
