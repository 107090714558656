import DataSelect from 'components/common/dataSelect';
import i18n from 'utils/i18n';
import { Q_CLUBS } from './params';

const QclubSelect = (props) => {
  const data = Object.keys(Q_CLUBS).map((key) => ({ value: Q_CLUBS[key], name: i18n(Q_CLUBS[key].toUpperCase()) }));

  return (
    <DataSelect
      dataObject={data}
      itemLabelPath="name"
      itemValuePath="value"
      {...props}
    />
  );
};

export default QclubSelect;
