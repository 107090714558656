import Proptypes from 'prop-types';
import Typography from '@mui/material/Typography';
import i18n from 'utils/i18n';

const Logo = ({ short }) => (
  <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
    <img src="/logo_2.png" style={{ width: '30px', height: '30px', borderRadius: '5px' }} alt="Q-people" />
    <Typography
      sx={{ fontFamily: '"Blinker" ,"Helvetica","Arial",sans-serif', fontWeight: ' bold' }}
      variant="h6"
      color="secondary"
    >
      {!short && i18n('APP_NAME')}
    </Typography>
  </div>
);

Logo.propTypes = {
  short: Proptypes.bool,
};

Logo.defaultProps = {
  short: false,
};

export default Logo;
